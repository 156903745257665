<template>
  <div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px" viewBox="0 0 800 600" style="enable-background:new 0 0 800 600;" xml:space="preserve">
                   <g id="Algeria">

	<path :style="city_ids.includes(1) ? 'fill:#3DB2FF' : ''"
        @mouseleave="onLeave"
        @mouseover="onHover(1)"
        @mousemove="onMove($event)"
        class="st0 enabled" d="M357.153,489.866l-0.37-0.261l-2.264-1.597l-2.266-1.596l-2.265-1.596l-2.264-1.597l-2.264-1.598l-2.265-1.6
		l-2.263-1.6l-2.266-1.6l-2.265-1.6l-2.264-1.599l-2.265-1.602l-2.263-1.604l-2.266-1.604l-2.263-1.603l-2.263-1.606L320.552,464
		l-2.266-1.605l-2.262-1.608l-2.265-1.608l-2.263-1.607l-2.266-1.608l-2.263-1.609l-2.265-1.608l-2.263-1.612l-2.266-1.612
		l-2.262-1.612l-2.265-1.612l-2.265-1.612l-2.266-1.614l-2.264-1.616l-2.263-1.616l-2.265-1.616l-2.265-1.617l-2.264-1.616
		l-2.265-1.619l-2.263-1.62l-2.086-1.489l-2.081-1.491l-2.082-1.491l-2.084-1.49l-2.084-1.492l-2.084-1.492l-2.08-1.494
		l-2.083-1.493l-2.082-1.496l-2.084-1.496l-2.084-1.495l-2.082-1.496l-2.083-1.497l-2.081-1.497l-2.084-1.499l-2.083-1.499
		l-2.903-2.092l-1.995-1.373l-1.995-1.372l-1.993-1.375l-1.994-1.375l-0.003-0.001l-0.005-0.005l-0.002-0.003l-4.536-3.087
		l-4.536-3.091l-4.536-3.093l-4.538-3.097l-3.163-2.061l-0.641-0.417l0.002-0.002l0.324-0.305l69.903-49.114l-20.29-40.32
		l5.175-0.463l4.67,2.389l2.742,1.887l6.438,3.198l5.482,1.089l6.357-2.576l3.672-2.293l8.233-6.473l3.251-1.912l2.973-1.165
		l23.983-4.296l3.468-2.545l16.371-22.098l15.638-29.148l9.174-9.781l2.412-2.044l4.981-3.202l27.502-13.991l-0.357,24.424
		l-3.353,23.195l3.552,31.803l1.432,16.244l-0.348,5.983l-1.444,6.428l-3.297,37.253l-0.199,0.722l-0.293,0.678l-0.498,0.713
		l-1.124,1.237l-0.814,0.711l-1.667,1.755l-0.123,0.084l-0.186,0.075l-2.728,0.42l-0.309,0.086l-0.174,0.08l-0.211,0.173
		l-1.634,2.297l-0.702,0.784l-0.45,0.319l-0.742,0.382l-2.113,0.629l-0.288,0.036l-3.604-0.029l-0.228,0.026l-0.952,0.331
		l-0.394,0.035l-1.44-0.203l-1.906,0.141l-0.227,0.076l-0.215,0.12l-1.772,1.32l-1.911,0.704l-1.191,0.598l-0.24,0.18l-0.51,0.657
		l-0.267,0.623l0.053,0.388l0.296,0.419l2.223,2.111l4.111,5.63l0.265,0.507l0.207,0.544l0.158,0.576l0.089,0.63l0.081,1.684
		l-0.381,2.831l0.195,0.812l0.328,0.615l4.42,3.387l0.34,0.314l0.154,0.27l0.053,0.418l-0.186,93.532l0.496,0.931l1.306,1.673
		l57.605,33.974l2.729,2.535l1.93,2.799l0.804,6.393l0.211,41.298v0.043v0.011l-1.953,0.383l-4.467,0.88l-2.739,0.537h-0.003
		l-2.118,0.519l-0.67,0.036l-0.383-0.059l-0.262-0.11l-0.613-0.388l-1.54-1.529l-1.207-0.524l-0.554-0.319l-0.519-0.401
		l-0.467-0.464l-0.438-0.628l-0.038-0.499l0.238-0.492l0.386-0.609l0.2-0.54l0.132-0.251l0.351-0.229l0.567-0.594l0.116-0.473
		l0.13-1.074l0.238-0.507l0.486-0.417l0.554-0.304l0.478-0.377l0.257-0.633l-0.067-0.611l-0.281-0.445l-0.332-0.412l-0.229-0.508
		l0.005-0.263l0.143-0.55l0.016-0.178l-0.111-0.241l-0.135-0.108l-0.14-0.086l-0.138-0.166l-0.335-1.015l-0.03-1.071l0.484-5.598
		l-0.105-0.38l-0.365-0.355l-0.408-0.2l-0.945-0.284l-0.432-0.203l-1.513-1.213l-3.309-1.467l-7.166-1.517l-1.434-0.06l-2.385-0.467
		l-0.284-0.021l-0.508-0.116l-0.943-0.52l-0.508-0.203l-0.632-0.144l-0.4-0.147l-0.305-0.302l-1.059-1.954l-0.829-1.184
		l-0.964-1.052l-1.604-1.285l-0.454-0.274l-0.481-0.128l-0.551,0.099l-0.605,0.396l-1.064,1.018l-0.686,0.304l-0.389-0.05
		l-1.645-0.582l-0.262-0.166l-0.202-0.181l-0.222-0.139l-0.311-0.042l-0.367,0.106l-0.443,0.417l-0.284,0.14l-0.575-0.019
		l-0.197-0.344l-0.092-0.527l-0.254-0.571l-0.408-0.315l-0.421-0.03l-0.47,0.065l-0.567-0.036l-0.551-0.262l-3.109-2.599
		l-0.259-0.406l0.022-2.09l-0.159-0.995l-0.529-0.798l-1.672-1.291l-1.029-0.544l-0.964-0.157l-0.462-0.304l-0.478-0.146
		l-1.053-0.176l-1.129-0.354l-0.446-0.319l-0.402-0.526l-0.548-0.972l-0.362-0.3l-0.61-0.166l-0.551,0.012l-1.04,0.227l-0.554,0.009
		l-0.594-0.081l-0.365-0.149l-0.192-0.354l-0.07-0.693l0.019-0.549l0.581-2.52l0.329-3.058l-0.057-0.616l-0.486-1.797l-0.335-0.566
		l-3.063-2.147l-2.266-1.589l-2.263-1.588l-2.264-1.589l-2.266-1.588l-2.264-1.592l-2.263-1.592l-2.265-1.593l-2.264-1.592
		l-2.265-1.593l-2.262-1.592l-2.266-1.594L357.153,489.866z"/>
                     <path :style="city_ids.includes(11) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(11)"
                           @mousemove="onMove($event)" class="st0" d="M669.852,442.604l-1.777,1.145l-1.78,1.143l-1.777,1.142l-1.777,1.142l-1.78,1.142l-1.78,1.142l-1.775,1.14
		l-1.78,1.14l-1.78,1.142l-1.775,1.14l-1.78,1.139l-1.78,1.141l-1.775,1.139l-1.78,1.138l-1.78,1.139l-1.78,1.139l-1.777,1.136
		l-1.78,1.138l-1.78,1.138l-1.777,1.136l-1.777,1.134l-1.78,1.137l-1.775,1.135l-1.78,1.135l-1.78,1.138l-1.777,1.134l-1.777,1.134
		l-1.78,1.134l-1.777,1.135l-1.777,1.133l-1.78,1.133l-1.777,1.132l-1.777,1.133l-1.78,1.132l-1.777,1.131l-1.78,1.133l-1.777,1.132
		l-1.777,1.13l-1.78,1.13l-1.777,1.131l-1.777,1.128l-1.78,1.131l-1.777,1.129l-1.777,1.13l-1.78,1.127l-1.78,1.129l-1.777,1.128
		l-1.78,1.129l-1.777,1.128l-1.78,1.128l-1.777,1.125l-1.78,1.128l-1.777,1.126l-1.777,1.125l-1.78,1.125l-1.78,1.125l-1.775,1.126
		l-1.78,1.124l-1.78,1.124l-1.775,1.125l-1.78,1.125l-1.78,1.123l-1.775,1.124l-1.78,1.122l-3.425,2.158l-2.59,2.263l-1.864,1.626
		l-1.866,1.629l-1.866,1.625l-1.867,1.626l-2.026,1.767l-0.986,0.888l-1.507,1.384l-1.648,1.513l-1.648,1.511l-1.645,1.51
		l-1.65,1.51l-1.646,1.511l-1.648,1.509l-1.65,1.51l-1.642,1.508l-1.65,1.509l-1.648,1.507l-1.648,1.506l-1.648,1.507l-1.648,1.505
		l-1.648,1.506l-1.65,1.504l-1.648,1.505l-2.058,1.876l-1.132,0.799l-1.175,0.446l-3.368,0.685l-2.604,0.529l-5.724,1.161
		l-5.724,1.163l-2.604,0.527l-4.932,1.002l-4.929,1l-4.929,1.002l-4.929,1.001l-4.47,0.88l-4.47,0.88l-2.515,0.494v-0.011v-0.043
		l-0.211-41.298l-0.804-6.393l-1.93-2.799l-2.729-2.535l-57.605-33.974l-1.306-1.673l-0.496-0.931l0.186-93.532l-0.053-0.418
		l-0.154-0.27l-0.34-0.314l-4.42-3.387l-0.328-0.615l-0.195-0.812l0.381-2.831l-0.081-1.684l-0.089-0.63l-0.158-0.576l-0.207-0.544
		l-0.265-0.507l-4.111-5.63l-2.223-2.111l-0.296-0.419l-0.053-0.388l0.267-0.623l0.51-0.657l0.24-0.18l1.191-0.598l1.911-0.704
		l1.772-1.32l0.215-0.12l0.227-0.076l1.906-0.141l1.44,0.203l0.394-0.035l0.952-0.331l0.228-0.026l3.604,0.029l0.288-0.036
		l2.113-0.629l0.742-0.382l0.45-0.319l0.702-0.784l1.634-2.297l0.211-0.173l0.174-0.08l0.309-0.086l2.728-0.42l0.186-0.075
		l0.123-0.084l1.667-1.755l0.814-0.711l1.124-1.237l0.498-0.713l0.293-0.678l0.199-0.722l3.297-37.253l1.444-6.428l0.348-5.983
		l-1.432-16.244l23.695-2.607l21.68,17.233l24.434,2.5l31.42-0.86l13.001,10.363l1.586,1.536l0.877,2.383l0.579,3.589l-1.045,0.887
		l-7.024,2.735l-6.477,6.235l-1.95,1.416l-1.646,1.455l-0.913,1.487l-0.567,2.499l0.644,3.488l2.875,10.001l0.077,0.211l0.13,0.249
		l0.249,0.351l1.102,1.145l5.131,3.959l0.956,0.942l0.496,0.848l0.247,0.819l0.093,0.567l0.012,0.381l-0.024,0.279l-0.117,0.463
		l-2.166,5.175l-0.198,0.677l-0.109,0.625l-0.057,0.932l0.053,0.463l0.097,0.327l0.122,0.172l0.138,0.133l0.172,0.123l2.724,1.341
		l0.204,0.161l5.518,7.384l0.321,0.327l0.182,0.153l0.235,0.153l2.81,1.193l0.681,0.4l0.824,0.38l6.447,1.796l0.385,0.759
		l0.289,1.274l0.292,5.452l2.964,3.72l0.77,0.765l0.398,0.598l0.263,0.503l0.113,0.307l0.069,0.332l-0.004,0.32l-0.02,0.26
		l-0.049,0.249l-0.101,0.272l-0.126,0.242l-1.168,1.672l-0.138,0.248l-0.498,1.352l-0.053,0.189l-0.062,0.315l0.008,3.105
		l0.544,2.276l0.178,0.39l0.304,0.402l0.475,0.418l1.687,1.141l0.425,0.43l0.361,0.449l0.722,1.311l0.782,0.989l0.296,0.449
		l0.184,0.21l0.158,0.141l0.478,0.316l0.47,0.427l0.15,0.112l0.155,0.086l1.171,0.435l1.018,0.152l1.067-0.083l0.178,0.007
		l0.162,0.034l0.138,0.055l0.239,0.156l0.459,0.426l0.154,0.107l0.166,0.071l0.308,0.091l0.142,0.06l0.15,0.085l0.369,0.304
		l0.159,0.105l0.162,0.062l0.162,0.001l0.34-0.029l0.17,0.01l0.146,0.046l0.475,0.302l0.178,0.139l0.677,0.69l0.247,0.322
		l0.304,0.296l1.12,0.678l0.126,0.054l0.605,0.182l0.182,0.098l0.028,0.02l1.606,1.657l0.304,0.251l0.536,0.273l0.235,0.161
		l0.588,0.632l0.3,0.254l0.808,0.517l0.126,0.064l0.077,0.028l1.703,0.31l1.298,0.053l0.682,0.112l1.958,0.617l0.953,0.617
		l0.417,0.179l0.162,0.034l0.555,0.036l0.158,0.043l0.13,0.069l0.799,0.728l0.166,0.104l2.405,0.738l2.124,0.24l0.67-0.018
		l0.263,0.025l0.312,0.071l0.178,0.113l0.203,0.184l0.313,0.375l0.304,0.477l1.128,2.444l0.624,0.953l0.697,0.865l0.743,0.76
		l0.263,0.204l0.182,0.073l0.361,0.06l78.334,0.036h0.007l2.931,5.988l1.831,3.728L669.852,442.604z"/>
                     <path :style="city_ids.includes(37) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(37)"
                           @mousemove="onMove($event)" class="st0" d="M209.096,385.398l-3.74-2.436l-4.381-2.86l-4.381-2.861l-4.38-2.863l-5.173-3.382l-5.172-3.386l-5.171-3.389
		l-5.173-3.394l-5.173-3.396l-5.171-3.401l-5.171-3.401l-5.174-3.408l-5.171-3.408l-5.17-3.415l-5.174-3.415l-5.174-3.422v-2.767
		v-2.768v-2.769v-2.773v-1.771v-1.77v-1.769v-1.771v-1.773v-1.774v-1.774v-1.776v-1.775v-1.777v-1.779v-1.779v-1.781v-1.781v-1.781
		v-1.784v-1.36l0.094-0.802l0.292-0.562l0.49-0.425l3.346-1.822l1.176-0.945l1.187-0.664l0.329-0.339l0.897-1.595l0.393-0.321
		l0.92-0.414l0.431-0.259l1.733-1.664l1.781-1.219l2.944-1.309l0.871-0.614l2.386-2.285l2.767-1.881l1.598-1.506l1.255-0.654
		l0.4-0.32l1.596-1.63l0.893-0.397l1.222,0.054l1.155,0.2l0.585,0.022l0.577-0.067l0.543-0.201l2.971,0.171l2.374-2.516l2.922-1.259
		l2.009-0.21l2.922,0.21l4.566,1.888l2.192-2.098l3.653-0.42l3.836-1.05l1.808-0.208l1.844-0.212h3.836h3.288l19.182,40.952
		l39.623-10.684l20.29,40.32l-69.903,49.114l-0.324,0.305L209.096,385.398z"/>
                     <path :style="city_ids.includes(8) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(8)"
                           @mousemove="onMove($event)" class="st0" d="M274.426,199.961l4.16-1.262l4.566-0.645l-1.096-3.01l-1.802-4.812l1.504-0.853l4.851-0.413l4.689-1.312
		l3.594,0.168l1.658,0.076l1.876,0.089l2.046,0.096l2.176,0.104l2.258,0.107l2.297,0.108l2.289,0.107l2.238,0.105l2.142,0.1
		l2.001,0.096l1.815,0.086l1.587,0.075l1.313,0.061l0.993,0.046l0.627,0.03l0.221,0.011l1.97,0.094l1.025-0.247l0.516-1.097
		l-0.131-0.643l-0.424-0.38l-0.546-0.166l-0.5,0.006l-0.971,0.389l-0.423-0.008l-0.115-0.503l0.101-0.194l0.431-0.344l0.162-0.164
		l0.166-0.33l0.038-0.254l-0.008-0.261l0.054-0.368l0.41,0.012l3.706-0.442l1.727,0.036l0.566,0.144l0.473,0.174l0.24,0.129
		l0.18,0.132l0.29,0.271l0.258,0.302l0.311,0.433l0.311,0.57l0.812,1.997l0.59,0.096l1.321-0.167l21.055-6.712l-9.452,27.636
		l0.008,1.184l0.799,1.643l5.651,6.517l14.456,12.645l-15.638,29.148l-16.371,22.098l-3.468,2.545l-23.983,4.296l-2.973,1.165
		l-3.251,1.912l-8.233,6.473l-3.672,2.293l-6.357,2.576l-5.482-1.089l-6.438-3.198l-2.742-1.887l-4.67-2.389l-5.175,0.463
		l-39.623,10.684l-19.182-40.952l3.105-0.63l3.288-0.42l0.913,2.73l2.192,0.839l2.557-0.839l2.74-3.57l2.374-3.785l1.827-3.579
		l2.557-2.74l2.74-1.898l2.922-2.533l2.192-1.69l2.74-1.479l3.836-1.692l3.653-2.963l2.922-3.813l2.557-1.484l3.105-0.848
		l4.018-0.212l4.384-1.061l4.931-2.548l-0.183-1.913l-0.183-1.914l1.279-1.277l1.461-2.342l-1.413-2.285l-0.035-0.593l-0.116-0.541
		l-0.251-0.564l-0.307-0.279L261.092,219l-0.465-0.45l-0.716-1.15l-0.374-0.399l-0.456-0.118l-0.388,0.207l-0.764,0.726
		l-0.466,0.207l-0.435-0.028l-0.31-0.275l-0.092-0.53l0.174-0.598l0.554-0.939l0.054-0.698l-0.165-2.309l0.105-0.56l0.332-0.413
		l1.438-1.057l1.933-0.125l0.365-7.928l1.78-0.935l1.114,0.255l0.972-0.086l7.64-1.381L274.426,199.961z"/>
                     <path :style="city_ids.includes(36) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(36)"
                           @mousemove="onMove($event)" class="st0" d="M581.875,35.055l0.082,0.865l-0.022,0.394l0.089,0.335l0.897,1.379l-0.022,0.397l-0.467,0.336l-1.812,0.732
		l-0.899,0.187l-0.978-0.068l-1.01,0.123l-0.808,0.401l-0.167,0.519l0.913,0.482l0.527,0.666l-0.224,1.146l-0.597,1.142
		l-0.586,0.652l-3.163,1.928l-1.688,0.639l-0.754,0.481l-0.616,0.753l-0.053,1.1l-0.009,0.005l-0.227,0.134l-0.451,0.364
		l-0.373,0.418l-0.535,0.794l-0.277,0.286l-0.34,0.267l-0.377,0.217l-0.365,0.161l-0.39,0.121l-0.438,0.086l-0.267-0.064
		l-0.15-0.217l-0.109-1.502l-0.397-0.825l-0.135-0.291l-0.073-0.244l-0.036-0.075l-0.101-0.134l-0.235-0.16l-4.724-2.005
		l-0.348-0.254l-0.186-0.329l-0.147-0.502l-0.178-0.342l-0.28-0.363l-0.871-0.758l-0.285-0.183l-1.236-0.184l0.381-0.721
		l0.717-0.918l0.471-1.038l0.15-0.236l0.272-0.197l0.559-0.197l0.138-0.233l0.073-0.373l0.053-3.057l0.078-0.208l0.065-0.105
		l0.174-0.054l0.111-0.049l0.944,0.706l1.478,0.449l1.097,0.158l3.698-0.916l0.602-0.32l0.523-0.471l3.662-1.84l0.47,0.307
		l0.459,0.488l0.506,0.255l1.18-0.061l0.593,0.061l0.472,0.245l0.387-0.157l0.334,0.152l0.334,0.274l0.374,0.197l0.902,0.086
		l0.898-0.086l2.727-1.149L581.875,35.055z"/>
                     <path :style="city_ids.includes(39) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(39)"
                           @mousemove="onMove($event)" class="st0" d="M558.505,147.915l0.037,4.147l0.4,1.461l0.672,0.742l0.962,0.292l1.253,0.118l0.953,0.325l2.896,2.101
		l0.508,0.264l0.535,0.166l0.548,0.038l0.546-0.123l0.583,0.359l2.471,3.88l2.652,4.154l0.354,0.994l0.354,4.366l0.251,3.204
		l0.305,1.022l0.036,0.039l0.715,0.785l3.206,2.051l4.173,3.037l5.467,3.969l4.4,3.191l0.362,0.435l0.294,0.583l0.478,2.209h-0.005
		l-45.717-0.064l-3.76-0.435l-3.634-1.128l-2.651-2.762l-2.053-2.988l-3.228-5.837l-1.14-2.596l-5.722-20.091l-2.254-4.995
		l-4.85-1.859l-30.967-2.032l2.973-4.099l2.519-4.286l1.379-1.796l1.124-1.168l0.328-0.62l0.219-0.717l1.018-6.691l-0.024-1.556
		l-0.081-0.682l-0.259-1.165l-0.423-1.093l-0.276-0.534l-0.814-1.2l-0.958-1.173l-1.853-1.62l-0.138-0.166l-0.097-0.237l0.061-0.388
		l0.109-0.396l0.19-0.389l0.186-0.299l0.284-0.28l0.304-0.182l1.071-0.424l0.47-0.252l0.293-0.318l0.057-0.439l-0.036-0.516
		l0.17-0.432l0.142-0.173l0.105-0.049l0.093,0.028l1.626,0.76l2.498,0.796l13.625,1.739l5.09-1.077l0.474,0.017l4.157,1.138
		l1.517,0.712l0.263,0.293l0.19,0.342l0.279,0.31l0.398,0.289l0.494,0.235l0.385,0.123l0.56,0.101l0.827-0.016l0.828-0.156
		l0.94-0.354l0.913-0.573l0.909-0.787l0.498,1.566l0.782,0.882l5.637,3.313l1.484,0.487l3.094,0.566l1.741,0.934l1.796,0.542
		l1.476,0.174l5.503-0.172h0.005h0.022l-0.569,0.591l-0.359,0.854l-0.081,2.325l-0.543,3.161l0.132,1.224l0.357,1.731l0.181,0.539
		l0.751,1.465l0.267,1.633l0.224,0.801l1.661,2.912l2.001,3.506l0.394,1.229L558.505,147.915z"/>
                     <path :style="city_ids.includes(12) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(12)"
                           @mousemove="onMove($event)" class="st0" d="M568.807,112.44l-0.22,0.197l-2.482,1.141l-1.091,0.785l-2.266,0.999l-1.021,0.745l-0.513,1.112l-0.983,3.744
		l-0.235,0.514l-0.327,0.397l-0.494,0.265l-1.661,0.268l-1.016,0.512l-0.716,0.746l-0.64,0.854l-1.054,1.096h-0.022h-0.005
		l-5.503,0.172l-1.476-0.174l-1.796-0.542l-1.741-0.934l1.802-4.3l0.596-2.286l0.454-2.962l0.255-1.004l0.508-1.482l0.15-0.312
		l0.101-0.146l0.332-0.348l0.097-0.158l0.5-1.202l0.96-1.374l0.504-0.934l0.587-1.423l0.069-0.259l0.065-0.367v-0.458l-0.024-0.274
		l-0.036-0.212l-0.393-1.207l-0.033-0.16l-0.016-0.174l0.109-1.716l-0.036-0.249l-0.057-0.199l-0.081-0.131l-0.138-0.276
		l-0.053-0.15l-0.036-0.158l-0.02-0.17l-0.008-0.178l0.02-0.738l-0.02-0.171l-0.053-0.149l-0.091-0.125l-0.117-0.11l-0.15-0.084
		l-0.178-0.048l-0.352-0.016l-0.17-0.03l-0.413-0.198l-0.123,0.016l-0.138,0.106l-0.117,0.199l-0.174,0.366l-0.15,0.172l-0.17,0.093
		l-0.154,0.043l-0.138,0.069l-0.105,0.118l-0.093,0.187l-0.077,0.23l-0.099,0.602l0.004,0.206l0.07,0.124l0.097,0.116l0.081,0.127
		l0.065,0.142l0.036,0.16l0.024,0.165l0.036,0.521l0.045,0.156l0.113,0.288l0.004,0.156l-0.077,0.148l-0.203,0.122l-0.15-0.026
		l-0.109-0.097l-0.257-0.569l-0.101-0.299l-0.061-0.326l-0.049-0.152l-0.122-0.29l-0.158-0.264l-0.049-0.151l-0.02-0.171
		l0.004-0.366l-0.012-0.175l-0.032-0.165l-0.053-0.148l-0.028-0.163l0.065-0.269l0.178-0.379l0.86-1.286l0.794-0.79l0.937-1.264
		l1.557-1.721l0.365-0.619l0.255-0.638l0.117-0.851l-0.069-0.588l-0.166-0.543l-0.109-0.497l0.057-0.509l0.279-0.614l0.036-0.544
		l-0.085-0.488l-0.032-0.473l0.166-1.084l-0.004-0.474l-0.142-0.359l-0.008-0.355l0.338-0.344l2.473-0.992l0.633-0.627l6.026-4.683
		l0.409-0.166l0.43-0.091l0.419-0.143l0.45-0.292l0.081-0.46l-0.097-0.594l-0.409-1.359l-0.085-0.714l0.057-0.857l1.013-2.381
		l0.065-0.267l-0.081-0.384l-0.008-0.191l0.008-0.112l0.113-0.522l0.524-0.677l0.267-0.442l0.166-0.22l0.101-0.105l0.275-0.211
		l0.99-0.503l0.239-0.195l0.105-0.118l0.077-0.138l0.065-0.158l0.101-0.335l0.069-0.155l0.131-0.127l0.178-0.115l0.288-0.133
		l0.908-0.205l1.124-0.08l0.824-0.174l0.304-0.131l0.174-0.043l0.957-0.067l0.178-0.055l0.239-0.134l0.073-0.053l0.101-0.089
		l0.117-0.032l0.154,0.037l0.321,0.354l0.259,0.195l0.203,0.118l0.995,0.401l0.011,0.004l-0.554,2.019l-0.151,2.566l0.113,1.367
		l0.311,1.127l1.278,2.106l0.446,1.052l0.095,0.99l0.257,2.682l-0.008,0.949l-0.937,2.275l-0.284,1.119l-0.054,1.171l0.167,1.313
		l0.602,1.14l0.989,0.485l1.089,0.341l0.899,0.703l-0.259,0.628l-1.58,2.436l-1.234,1.369l-0.346,1.131l-0.459,2.35l-0.624,1.702
		l-0.265,1.234l0.049,1.185l0.486,3.211l-0.073,0.432l-0.205,0.286l-0.036,0.05l-0.84,0.892l-0.308,0.475l-0.073,0.494l0.675,1.066
		l-0.2,0.356l-0.94,0.594l-0.165,0.259l-0.184,0.553l-0.113,0.218l-0.208,0.213l-0.616,0.376L568.807,112.44z"/>
                     <path :style="city_ids.includes(41) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(41)"
                           @mousemove="onMove($event)" class="st0" d="M575.417,54.058l-0.027,0.169l-1.237,3.479l-0.124,1.338l0.124,0.643l0.195,0.601l0.068,0.584l-0.238,0.612
		l-0.294,0.537l-0.551,2.379l-0.084,1.78l-0.105,0.383l-0.011-0.004l-0.995-0.401l-0.203-0.118l-0.259-0.195l-0.321-0.354
		l-0.154-0.037l-0.117,0.032l-0.101,0.089l-0.073,0.053l-0.239,0.134l-0.178,0.055l-0.957,0.067l-0.174,0.043l-0.304,0.131
		l-0.824,0.174l-1.124,0.08l-0.908,0.205l-0.288,0.133l-0.178,0.115l-0.131,0.127l-0.069,0.155l-0.101,0.335l-0.065,0.158
		l-0.077,0.138l-0.105,0.118l-0.239,0.195l-0.99,0.503l-0.275,0.211l-0.101,0.105l-0.166,0.22l-0.267,0.442l-0.524,0.677
		l-1.82-1.857l-0.438-0.301l-0.512-0.252l-0.66-0.035l-0.401,0.258l-0.267,0.39l-0.163,0.451l-0.219,0.367l-0.474,0.433
		l-0.113,0.285l-0.024,0.382l-0.077,0.405l-0.207,0.265l-0.483,0.103l-1.683-0.081l-0.348-0.206l-0.409-1.054l-0.227-0.426
		l-0.431-0.243l-1.061-0.302l-0.398-0.212l-0.397-0.319l-0.905-1.071l-0.498-0.475l-0.786-1.156l-1.132-2.496l0.024-1.533
		l0.527-0.025l0.3-0.051l0.273-0.165l0.194-0.266l0.316-0.674l0.251-0.323l0.349-0.239l1.695-0.366l0.512-0.035l0.344-0.078
		l0.304-0.176l0.385-0.538l0.244-0.183l2.615-0.91l2.604-1.315l0.905-0.069l0.45-0.109l0.486-0.228l0.406-0.328l0.146-0.643
		l-0.138-0.495l-0.288-0.473l-0.172-0.337l-0.049-0.329l-0.02-0.34l-0.049-0.269l-0.365-1.01l0.041-0.192l0.186-0.01l1.063,0.341
		l0.742-0.058l3.043-1.683l0.397,0.825l0.109,1.502l0.15,0.217l0.267,0.064l0.438-0.086l0.39-0.121l0.365-0.161l0.377-0.217
		l0.34-0.267l0.277-0.286l0.535-0.794l0.373-0.418l0.451-0.364l0.227-0.134l0.009-0.005l-0.001,0.02l1.07,0.44l2.269,0.297
		l0.505,0.262l0.494,0.138l0.403,0.247l0.221,0.598l0.051,0.623l-0.041,1.34L575.417,54.058z"/>
                     <path :style="city_ids.includes(30) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(30)"
                           @mousemove="onMove($event)" class="st0" d="M593.917,191.725l0.07,0.324l0.745,3.39l0.737,3.386l0.743,3.378l0.74,3.378l0.74,3.374l0.743,3.368
		l0.737,3.366l0.745,3.36l0.74,3.358l0.74,3.354l0.74,3.349l0.743,3.346l0.743,3.341l0.74,3.337l0.74,3.335l0.74,3.331l-6.094,3.379
		l0.613,0.99l1.555,1.859l-58.099,35.511l-5.467-0.865l-3.914,0.003l-9.578,4.086l-12.236,7.159l-31.42,0.86l-24.434-2.5
		l-21.68-17.233l9.368-5.48l1.387-1.748l2.145-8.167l1.225-1.708l1.91-2.233l3.187-2.877l2.118-3.815l6.484-47.564l1.573-7.304
		l2.377-5.618l2.112-4.04l1.03-2.94l0.97-1.723l0.385-0.526l0.654-0.719l3.75-2.453l5.135-4.842l6.257-9.591l-0.658-12.493
		l0.844-3.007l0.591-3.262l30.967,2.032l4.85,1.859l2.254,4.995l5.722,20.091l1.14,2.596l3.228,5.837l2.053,2.988l2.651,2.762
		l3.634,1.128l3.76,0.435l45.717,0.064H593.917z"/>
                     <path :style="city_ids.includes(33) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(33)"
                           @mousemove="onMove($event)" class="st0" d="M601.919,252.026l1.365,1.632l3.344,5.016l3.085,5.837l2.07,7.203l2.07,7.186l0.578,4.568l0.078,5.663
		l-0.627,4.986l-1.315,10.435l0.338,1.725l3.817,10.148l-0.043,1.162l-0.078,0.259l-1.775,5.887l-0.438,0.588l-0.735,0.4
		l-0.643,0.472l0.084,0.614l0.381,0.659l0.259,0.614l-0.208,0.566l-1.126,1.245l-0.373,0.624l-0.346,1.734l-0.432,1.16l-0.122,0.477
		l-0.013,0.488l2.215,7.835l0.508,3.066l0.251,0.574l0.286,0.265l1.17,0.656l0.413,0.354l0.103,0.421l-0.656,3.118l-0.076,1.019
		l0.3,0.813l0.06,0.62l-1.091,3.753l-0.492,0.589l-9.229,4.426l-0.032,0.582l-0.097,0.497l-0.23,0.423l-0.891,0.846l-0.478,1.158
		l-0.365,0.468l-0.646,1.376l0.611,1.617l3.663,5.149l3.989,5.6l3.209,4.495l3.992,5.59l1.005,1.85l0.351,1.833l0.108,3.802
		l0.116,4.078l0.054,3.998l0.327,0.769l3.889,2.294l0.492,0.779l0.459,2.672l0.327,1.001l0.473,0.531l3.436,2.777l0.489,0.192
		l1.037-0.105l3.047-1.135l2.89-1.074l0.567-0.065l0.565,0.11l4.983,1.66l6.237,2.072l8.291,2.754l1.082,0.359l0.856,0.468
		l0.673,0.879l1.812,3.702l1.885,3.855l0.04,0.081h-0.007l-78.334-0.036l-0.361-0.06l-0.182-0.073l-0.263-0.204l-0.743-0.76
		l-0.697-0.865l-0.624-0.953l-1.128-2.444l-0.304-0.477l-0.313-0.375l-0.203-0.184l-0.178-0.113l-0.312-0.071l-0.263-0.025
		l-0.67,0.018l-2.124-0.24l-2.405-0.738l-0.166-0.104l-0.799-0.728l-0.13-0.069l-0.158-0.043l-0.555-0.036l-0.162-0.034
		l-0.417-0.179l-0.953-0.617l-1.958-0.617l-0.682-0.112l-1.298-0.053l-1.703-0.31l-0.077-0.028l-0.126-0.064l-0.808-0.517
		l-0.3-0.254l-0.588-0.632l-0.235-0.161l-0.536-0.273l-0.304-0.251l-1.606-1.657l-0.028-0.02l-0.182-0.098l-0.605-0.182
		l-0.126-0.054l-1.12-0.678l-0.304-0.296l-0.247-0.322l-0.677-0.69l-0.178-0.139l-0.475-0.302l-0.146-0.046l-0.17-0.01l-0.34,0.029
		l-0.162-0.001l-0.162-0.062l-0.159-0.105l-0.369-0.304l-0.15-0.085l-0.142-0.06l-0.308-0.091l-0.166-0.071l-0.154-0.107
		l-0.459-0.426l-0.239-0.156l-0.138-0.055l-0.162-0.034l-0.178-0.007l-1.067,0.083l-1.018-0.152l-1.171-0.435l-0.155-0.086
		l-0.15-0.112l-0.47-0.427l-0.478-0.316l-0.158-0.141l-0.184-0.21l-0.296-0.449l-0.782-0.989l-0.722-1.311l-0.361-0.449l-0.425-0.43
		l-1.687-1.141l-0.475-0.418l-0.304-0.402l-0.178-0.39l-0.544-2.276l-0.008-3.105l0.062-0.315l0.053-0.189l0.498-1.352l0.138-0.248
		l1.168-1.672l0.126-0.242l0.101-0.272l0.049-0.249l0.02-0.26l0.004-0.32l-0.069-0.332l-0.113-0.307l-0.263-0.503l-0.398-0.598
		l-0.77-0.765l-2.964-3.72l-0.292-5.452l-0.289-1.274l-0.385-0.759l-6.447-1.796l-0.824-0.38l-0.681-0.4l-2.81-1.193l-0.235-0.153
		l-0.182-0.153l-0.321-0.327l-5.518-7.384l-0.204-0.161l-2.724-1.341l-0.172-0.123l-0.138-0.133l-0.122-0.172l-0.097-0.327
		l-0.053-0.463l0.057-0.932l0.109-0.625l0.198-0.677l2.166-5.175l0.117-0.463l0.024-0.279l-0.012-0.381l-0.093-0.567l-0.247-0.819
		l-0.496-0.848l-0.956-0.942l-5.131-3.959l-1.102-1.145l-0.249-0.351l-0.13-0.249l-0.077-0.211l-2.875-10.001l-0.644-3.488
		l0.567-2.499l0.913-1.487l1.646-1.455l1.95-1.416l6.477-6.235l7.024-2.735l1.045-0.887l-0.579-3.589l-0.877-2.383l-1.586-1.536
		l-13.001-10.363l12.236-7.159l9.578-4.086l3.914-0.003l5.467,0.865L601.919,252.026z"/>
                     <path :style="city_ids.includes(45) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(45)"
                           @mousemove="onMove($event)" class="st0" d="M323.776,184.651l0.416-2.825l0.35-1.116l0.261-0.548l0.423-0.557l0.425-0.224l1.083-0.158l0.97-0.402
		l0.867-0.666l1.515-1.702l-0.405-0.701l-7.305-5.643l-1.66-0.617l-0.858-0.734l-3.54-5.949l1.109-0.805l0.196-0.213l0.154-0.259
		l0.108-0.292l0.061-0.32l0.082-0.681l0.008-0.727l-0.169-0.646l-0.44-0.429l-0.762-0.557l-0.677-0.629l-0.54-0.768l-0.354-0.981
		l-0.47-0.892l-1.324-1.292l-0.239-1.027l0.004-3.077l0.271-0.794l0.358-0.788l0.49-1.747l0.392-0.586l0.331-0.854l-0.12-1.034
		l-1.178-2.832l-0.284-0.371l-0.456-0.34l-0.906-0.326l-0.401-0.279l-0.158-0.496l0.119-0.483l0.253-0.318l0.304-0.267l0.265-0.336
		l0.199-0.464l0.019-0.346l-0.275-0.917l-0.236-1.547l0.088-1.477l1.218-5.077l0.065-0.632l-0.064-0.404l0.361-0.256l4.662-2.854
		l0.951-0.259l0.357-0.035l0.791,0.046l1.28-0.069l0.592,0.074l1.764-0.189l0.276-0.064l0.2-0.085l0.366-0.278l0.331-0.329
		l0.127-0.095l1.588-0.701l0.3-0.193l0.473-0.376l0.683,0.415l0.354,0.167l0.455,0.108l0.563-0.056l0.762-0.203h0.323l0.284,0.107
		l0.5,0.32l0.409,0.325l0.513,0.266l0.598,0.167l1.984,0.16l1.199-0.204l0.672-0.265l0.602-0.362l1.642-1.302l1.086-0.698
		l0.519-0.202l0.509-0.084l0.513,0.024l0.544,0.142l0.493,0.258l0.401,0.314l0.365,0.469l0.2,0.313l0.413,1.148l-0.324,0.75
		l-0.04,0.393l0.127,0.611l0.155,0.495l1.987,3.925l0.702,1.855l0.04,0.396l-0.054,0.206l-0.03,0.306l0.095,0.236l0.456,0.575
		l0.143,0.122l0.13,0.045l0.094-0.08l0.072-0.13l0.026-0.168l-0.008-0.168l-0.032-0.264v-0.122l0.013-0.143l0.042-0.119l0.042-0.073
		l0.192-0.157l0.151-0.051l0.176-0.01l0.219,0.056l0.168,0.005l0.115-0.071l0.082-0.135l0.219-0.476l0.921-1.338l0.833-0.935
		l0.112-0.095l0.116-0.078l0.254-0.119l0.489-0.139l0.891-0.362l0.232-0.179l0.14-0.064l0.165-0.036l0.648,0.035l0.798,0.167
		l0.212,0.139l0.127,0.155l0.176,0.628l0.043,0.911l0.051,0.331l0.003,0.161l-0.057,0.207l-0.022,0.052l-0.012,0.062l0.004,0.094
		l0.045,0.18l0.12,0.085l0.147,0.017l0.147-0.049l1.153-0.617l1.245-0.908l0.563-0.319l0.439-0.081l-0.049,1.326l-0.876,6.396
		l-0.128,5.654l0.585,7.743l-0.12,2.127l-0.223,1.165l-1.436,4.846l-0.488,1.73l-0.22,1.234l-0.059,0.643l0.637,5.658l-0.046,0.419
		l-0.177,0.664l-2.234,5.984l-0.069,0.731l0.081,0.667l0.38,0.657l0.404,0.27l0.412,0.13l0.393,0.062l0.336,0.096l0.288,0.173
		l0.024,0.026l0.076,0.15l0.089,0.252l0.065,0.371l0.032,0.427l-0.019,0.535l-0.219,1.324l-0.718,2.286l-21.055,6.712l-1.321,0.167
		l-0.59-0.096l-0.812-1.997l-0.311-0.57l-0.311-0.433l-0.258-0.302l-0.29-0.271l-0.18-0.132l-0.24-0.129l-0.473-0.174l-0.566-0.144
		l-1.727-0.036l-3.706,0.442L323.776,184.651z"/>
                     <path :style="city_ids.includes(13) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(13)"
                           @mousemove="onMove($event)" class="st0" d="M313.357,126.5l-0.07-0.44l-1.864-5.825l-0.66-1.405l-1.001-1.195l2.788-3.397l-0.294-0.17l-0.952-0.289
		l-3.148-3.251l0.213-0.538l1.371-2.132l0.637-1.434l0.339-0.261l0.095-0.232l-0.475-0.488l-2.747-1.75l-0.886-0.841l-1.386-0.866
		l-0.359-0.433l-0.128-0.441l-0.034-0.435l-0.103-0.377l-0.331-0.257l-1.175-0.11l-0.881-0.573l-0.813-0.773l-0.974-0.706
		l-0.797-0.305l-0.467-0.633l-0.247-0.846l-0.038-1.256l0.587-0.218l0.104,0.04l0.091,0.013l0.083,0.047l0.08,0.138l0.771-0.393
		l0.906,0.201l0.944,0.405l0.882,0.222l1.69-0.238l0.6,0.199l0.217,0.039l0.173-0.068l0.453-0.369l2.451-0.71l0.678-0.499
		l0.219-0.051l0.787,0.025l0.342-0.073l0.406-0.294l0.857-1.016l1.672-0.57l0.291-0.195l0.214-0.566l3.114-2.664l0.509-0.176
		l2.482-0.27l0.689-0.207l0.145-0.079l0.053-0.05l0.03,0.062l0.024,0.3l0.036,0.113l0.099,0.078l0.186-0.037l0.184-0.007l0.189,0.06
		l0.157,0.134l0.14,0.255l0.034,0.2l-0.024,0.196l-0.084,0.374l-0.036,0.748l-0.031,0.146l-0.039,0.092l-0.084,0.108l-0.346,0.27
		l-0.051,0.128l0.063,0.293l0.026,0.34l0.043,0.159l0.119,0.12l0.176,0.083l0.322,0.012l0.213-0.033l0.184-0.056l0.141-0.071
		l0.321-0.088l0.181-0.012l0.189,0.011l5.837,3.143l1.248,0.317l0.189,0.101l0.086,0.035l1.484,1l0.088,0.148l0.012,0.214
		l-0.242,0.607l-0.282,0.457l-0.105,0.216l-0.004,0.004l-0.3,1.22l-0.055,0.633l-0.001,0.423l0.03,0.194l0.17,0.506l0.173,0.336
		l0.155,0.189l0.126,0.055h0.096l0.105,0.02l0.14,0.055l0.131,0.152l-0.003,0.158l-0.381,0.537l-0.108,0.267l0.042,0.278
		l0.139,0.109l0.213,0.002l0.343-0.121l0.35-0.065l0.282,0.043l0.07,0.264l-0.047,0.554l-0.19,1.058l0.012,0.757l0.271,0.569
		l0.538,0.279l0.679,0.069l1.671-0.17l0.13,0.07l0.093,0.115l0.216,0.404l0.009,0.223l-0.07,0.303l-0.378,0.81l-0.378,0.65
		l-0.508,1.115l-0.435,1.235l-0.089,0.13l-0.081,0.074l-0.204,0.098l-1.147,0.386l-0.302,0.187l-0.205,0.244l-0.039,0.285
		l0.096,0.216l0.126,0.171l0.276,0.292l0.099,0.131l0.155,0.265l0.053,0.119l0.047,0.188l0.014,0.138l-0.008,0.215l-0.086,0.259
		l-0.109,0.246l-0.416,0.503l-4.187,3.564l-1.029,1.092l-0.651,1.057l-0.473,0.376l-0.3,0.193l-1.588,0.701l-0.127,0.095
		l-0.331,0.329l-0.366,0.278l-0.2,0.085l-0.276,0.064l-1.764,0.189l-0.592-0.074l-1.28,0.069l-0.791-0.046l-0.357,0.035
		l-0.951,0.259l-4.662,2.854L313.357,126.5z"/>
                     <path :style="city_ids.includes(23) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(23)"
                           @mousemove="onMove($event)" class="st0" d="M546.859,30.587l0.099,0.043l0.111,0.12l0.106,0.056l0.164-0.123l0.189-0.236l0.115-0.073l0.132-0.015
		l0.251,0.092l0.485,0.293l0.264,0.063l0.221-0.071l0.668-0.377l0.353-0.001l0.072,0.029l0.017,0.131l0.187,0.289l0.109,0.369
		l0.168,0.21l0.355,0.091l0.525,0.008l0.23,0.064l0.234,0.152l0.427-0.264l0.466-0.163l0.938,0.715l0.778,0.827l0.861,0.663
		l1.168,0.232l0.257-0.069l0.23-0.117l0.253-0.073l0.33,0.056l0.296,0.142l0.266,0.177l0.232,0.212l0.206,0.24l1.255-0.12
		l0.262-0.106l0.478-0.538l0.249-0.23l-0.198,0.667l-0.325,0.723l-0.113,0.643l0.442,0.426l-0.464,0.931l0.008,0.007l-0.111,0.049
		l-0.174,0.054l-0.065,0.105l-0.078,0.208l-0.053,3.057l-0.073,0.373l-0.138,0.233l-0.559,0.197l-0.272,0.197l-0.15,0.236
		l-0.471,1.038l-0.717,0.918l-0.381,0.721l-0.52,0.838l-0.215,0.264l-0.243,0.225l-0.251,0.14l-0.276,0.091l-0.763,0.101
		l-0.389-0.022l-0.374-0.074l-0.381-0.157l-0.45-0.278l-0.142-0.067l-0.405-0.108l-0.143-0.155l-0.081-0.318l0.008-0.701
		l-0.211-0.442l-0.207-0.305l-1.898-1.078l-0.336-2.083l-0.504-1.482l0.008-0.424l0.077-0.334l0.15-0.317l0.073-0.419l-0.142-0.328
		l-0.279-0.264l-0.701-0.361l-0.358-0.619l-1.139-4.622L546.859,30.587z"/>
                     <path :style="city_ids.includes(21) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(21)"
                           @mousemove="onMove($event)" class="st0" d="M520.337,35.192l0.236-0.342l0.045-0.687l-0.1-0.54l-0.008-0.513l0.325-0.615l0.198-0.212l1.051-0.799
		l0.372-0.434l0.257-0.241l0.859-0.465l1.155-0.311L525.945,30l1.053,0.36l0.938,0.74l0.147,0.052l0.013,0.334l0.062,0.241
		l0.136,0.148l0.236,0.051l0.3,0.241l0.274,1.242l0.423,0.547l0.383-0.156l0.457,0.178l0.51,0.279l0.523,0.145l3.943,0.222
		l0.334,0.114l0.47,0.287l0.44,0.365l0.272,0.351l-0.011,0.535l0.778,0.295l1.023,0.098l0.706-0.037l2.323-0.723l0.727-0.412
		l0.413,0.184l0.659,0.165l0.619,0.019l0.274-0.245l0.234-0.317l1.106-0.947l0.37-0.197l0.159-0.152l0.211-0.354l0.187-0.405
		l0.081-0.309l-0.047-0.368l-0.132-0.254l-1.029-1.11l-0.77-0.645V30.36l0.289-0.044l0.508-0.164l0.274-0.016l0.264,0.084
		l0.485,0.283l0.24,0.061l0.057,0.024l0.026,0.182l1.139,4.622l0.358,0.619l0.701,0.361l0.279,0.264l0.142,0.328l-0.073,0.419
		l-0.15,0.317l-0.077,0.334l-0.008,0.424l0.504,1.482l0.336,2.083l-0.443,0.155l-0.515,0.285l-0.207,0.203l-0.296,0.442l-0.101,0.35
		l-0.109,0.764l-0.146,0.23l-0.269,0.138l-0.446,0.008l-0.604,0.141l-0.727,0.287l-2.94,1.568l-0.563,0.477l-0.662,0.843
		l-0.162,0.414l0.004,0.334l0.097,0.221l0.061,0.251l-0.036,0.169l-0.109,0.107l-0.239,0.115l-2.007,0.609l-0.629,0.346
		l-0.616,1.088l-1.938-0.494l-0.415-0.2l-0.405-0.049l-0.284-0.125l-0.093-0.235l0.069-0.217l0.162-0.19l0.178-0.139l0.13-0.139
		l0.069-0.143l0.02-0.177v-0.105l-0.057-0.375l-0.016-0.26l0.057-0.206l0.105-0.149l0.158-0.1l0.211-0.071l0.289-0.01l0.316,0.056
		l0.32,0.143l0.239,0.061l0.198-0.139l0.138-0.261l0.082-0.298v-0.217l-0.236-0.106l-0.458-0.035l-3.041,0.17l-1.16-0.296
		l-1.395-0.665l-0.17-0.272l-0.085-0.271l-0.15-0.152l-0.3-0.024l-1.387,1.103l-0.508,0.128l-1.041-0.225l-0.941-0.024l-1.221-0.071
		l0.008-1.373l0.544-1.248l-0.097-0.283l-0.204-0.21l-1.272-0.653l-1.367-1.51l-0.342-0.819l-0.162-0.539l-0.024-0.901l-0.065-0.509
		l-0.126-0.361l-0.17-0.294l-0.203-0.24l-0.231-0.202l-0.251-0.142l-0.277-0.059l-0.527-0.019l-0.097-0.111l-0.016-0.262
		l0.122-0.608l0.004-0.31l-0.045-0.199l-0.113-0.202l-0.442-0.454l-0.122-0.085L520.337,35.192z"/>
                     <path :style="city_ids.includes(18) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(18)"
                           @mousemove="onMove($event)" class="st0" d="M498.913,44.051l0.152-0.044h0.81l0.123-0.081l0.33-0.408l0.181-0.159l1.046-0.409l0.519-0.393l0.359-1.183
		l0.351-0.502l0.464-0.408l0.472-0.243l1.148-0.342l0.487-0.255l0.938-0.845l0.608-0.223l0.655-0.034l1.804,0.38l1.072-0.039
		l4.398-1l4.179-1.593l1.01-0.617l0.317-0.461l0.002,0.002l0.122,0.085l0.442,0.454l0.113,0.202l0.045,0.199l-0.004,0.31
		l-0.122,0.608l0.016,0.262l0.097,0.111l0.527,0.019l0.277,0.059l0.251,0.142l0.231,0.202l0.203,0.24l0.17,0.294l0.126,0.361
		l0.065,0.509l0.024,0.901l0.162,0.539l0.342,0.819l1.367,1.51l1.272,0.653l0.204,0.21l0.097,0.283l-0.544,1.248l-0.008,1.373
		l-3.386-0.673l-0.231-0.124l-0.113-0.157l-0.146-0.402l-0.158-0.088l-0.22,0.006l-0.701,0.42l-0.585,0.245l-0.178,0.186
		l-0.15,0.219l-0.158,0.182l-0.3,0.098l-0.466,0.023l-0.828-0.086l-1.031-0.242l-1.379-0.139l-0.904-0.28l-0.413-0.035l-0.524,0.086
		l-0.964,0.415l-0.394,0.281l-0.271,0.382l-0.138,0.335l-0.304,0.287l-0.512,0.18l-0.916,0.079l-0.633-0.017l-0.6-0.23l-0.401-0.214
		l-0.496-0.135l-0.316-0.046l-1.006,0.25l-1.614,0.605l-0.324-0.008l-0.336-0.094l-0.662-0.454l-0.284-0.061l-0.373,0.152
		l-0.239,0.179l-0.417,0.435l-0.228,0.161l-0.292,0.094l-0.312,0.003l-0.28-0.049l-0.304-0.163l-0.249-0.261l-0.158-0.497
		l-0.036-0.419l-0.138-0.568l-0.595-0.213l-0.475-0.372l-0.636-0.2l-0.134-0.148l-0.012-0.146l0.117-0.16l0.081-0.202l0.033-0.14
		l-0.182-0.84L498.913,44.051z"/>
                     <path :style="city_ids.includes(6) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(6)"
                           @mousemove="onMove($event)" class="st0" d="M477.05,36.501l0.821-0.037l1.963,0.17l1.857-0.223l0.472,0.085l4.254,1.817l0.481,0.461l0.14,0.22l0.315,0.06
		l0.362-0.001l0.255,0.035l0.187,0.166l0.23,0.402l0.102,0.119l0.438,0.211l0.515,0.163l0.538,0.088l0.487-0.016l-0.523,1.589
		l0.14,0.516l0.738,0.568l3.069,1.578l0.868,0.223l0.923,0.036l1.778-0.258l1.454-0.421l0.031,0.177l0.182,0.84l-0.033,0.14
		l-0.081,0.202l-0.117,0.16l0.012,0.146l0.134,0.148l0.636,0.2l0.475,0.372l-0.353,0.567l-0.074,0.187l-0.049,0.208l-0.036,0.433
		l0.012,0.225l0.049,0.266l0.086,0.267l0.061,0.282l-0.004,0.227l-0.078,0.197l-0.073,0.093l-0.138,0.121l-0.701,0.474l-0.49,0.259
		l-0.962,0.203l-0.852,0.404l-0.255,0.184l-0.154,0.183l-0.154,0.277l-0.097,0.412l0.02,0.364l-0.057,0.42l-0.203,0.293
		l-0.682,0.257l-0.567,0.082l-0.868-0.177l-0.385-0.263l-0.515-0.52l-0.004-0.273l0.182-0.727l0.004-0.339l-0.109-0.358l-0.19-0.322
		l-0.423-0.586l-0.041-0.18l0.069-0.129l0.057-0.058l0.273-0.189l0.235-0.221l0.113-0.259l-0.02-0.229l-0.15-0.226l-0.783-0.616
		l-0.385-0.376l-0.199-0.149l-0.259-0.116l-0.621-0.148l-0.308,0.018l-0.349,0.091l-0.309,0.387l-0.101,0.338l-0.049,0.403
		l-0.114,0.289l-0.243,0.213l-0.442,0.04l-0.296-0.076l-0.232-0.092l-0.138-0.039l-0.073-0.01l-1.143,0.654l-0.172,0.212
		l-0.04,0.069l0.004,0.066l0.053,0.091l0.117,0.152l0.613,0.508l0.207,0.22l0.012,0.213l-0.178,0.215l-0.478,0.259l-0.261,0.309
		l-0.105,0.321l-0.016,0.327l-0.081,0.337l-0.194,0.279l-0.365,0.325l-0.369,0.216l-0.565,0.159l-0.421,0.068l-1.82-0.13
		l-0.109-0.17l-0.049-0.085l-0.39-0.888l-0.154-0.209l-0.182-0.111l-0.235,0.005l-0.267,0.126l-0.389,0.326l-0.71,1.134
		l-0.316,0.293l-0.413,0.279l-0.893,0.433l-0.211,0.205l-0.118,0.303l-0.113,0.954l-0.012,0.447l0.065,0.372l0.097,0.358
		l-0.069,0.326l-0.259,0.176l-1.522,0.293l-0.924,0.353l-0.397,0.072l-1.753,0.092l-0.547-0.105l-0.397-0.398l-0.459-0.951
		l-0.361-1.672l-0.535-0.683l0.239-0.276l0.328-0.235l0.328-0.159l0.151-0.164l0.061-0.246l-0.081-0.485l0.028-0.396l0.081-0.324
		l0.089-0.225l0.032-0.267l-0.093-0.317l-0.536-0.667l-0.247-0.534l1.456-0.516l0.365-0.224l0.247-0.118l0.094-0.081l1.946-2.179
		l0.251-0.416l0.361-0.907l1.059-1.707l0.158-0.436l0.036-0.432l-0.13-0.273l-0.19-0.188l-0.231-0.114l-0.267-0.089l-0.273-0.126
		l-0.247-0.159l-0.166-0.249l-0.028-0.222l0.085-0.412l0.243-0.257l0.309-0.18l0.348-0.059l0.288,0.04l0.636,0.269l0.281,0.039
		l0.263-0.153l0.146-0.302l0.162-0.726l0.041-0.336l-0.036-0.283l-0.105-0.247l-1.075-1.622l-0.158-0.362l-0.057-0.517
		L477.05,36.501z"/>
                     <path :style="city_ids.includes(15) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(15)"
                           @mousemove="onMove($event)" class="st0" d="M462.623,36.447l1.202,0.158l0.506,0.273l1.089-0.59l0.34-0.078l0.315,0.066l0.666,0.291l0.351,0.068
		l0.323-0.098l0.691-0.447l0.332-0.122l0.434,0.037l0.717,0.327l0.374,0.08l3.146-0.444l0.321,0.05l0.396,0.133l0.374,0.181
		l0.249,0.192l0.317,0.173l0.844,0.024l0.37,0.158l0.81-0.366l0.259-0.011l0.047,0.227l0.057,0.517l0.158,0.362l1.075,1.622
		l0.105,0.247l0.036,0.283l-0.041,0.336l-0.162,0.726l-0.146,0.302l-0.263,0.153l-0.281-0.039L477,40.969l-0.288-0.04l-0.348,0.059
		l-0.309,0.18l-0.243,0.257l-0.085,0.412l0.028,0.222l0.166,0.249l0.247,0.159l0.273,0.126l0.267,0.089l0.231,0.114l0.19,0.188
		l0.13,0.273l-0.036,0.432l-0.158,0.436l-1.059,1.707l-0.361,0.907l-0.251,0.416l-1.946,2.179l-0.094,0.081l-0.247,0.118
		l-0.365,0.224l-1.456,0.516l-0.519-0.174l-1.091,0.259l-0.474-0.005l-1.107-0.319l-0.385-0.02l-0.836,0.367l-0.413,0.101
		l-6.538-0.265l-1.237-0.458l-0.977-0.578l-0.86-0.851l-0.685-0.465l-0.795-0.684l-0.737-1.296l0.049-1.079l0.073-0.233l0.166-0.272
		l0.194-0.103l0.223-0.061l0.243-0.023l0.236,0.03l0.17,0.129l0.117,0.173l0.142,0.132l0.227,0.041l0.255-0.072l0.207-0.284
		l0.142-0.309l0.24-0.358l0.263-0.061l0.284,0.069l0.827,0.305l0.52,0.03l0.304-0.111l0.308-0.286l0.255-0.343l0.447-0.455
		l0.154-0.264l-0.028-0.235l-0.438-0.494l-0.22-0.349l-0.146-0.283l-0.065-0.241l0.049-0.252l0.243-0.131l0.346-0.082l0.446-0.195
		l0.648-0.732l0.759-1.048l0.203-0.445l0.101-0.441l0.04-0.929L462.623,36.447z"/>
                     <path :style="city_ids.includes(35) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(35)"
                           @mousemove="onMove($event)" class="st0" d="M439.986,41.484l0.159-0.007l0.812-0.475l0.281-0.458l0.128-0.482l0.032-0.853l0.274-0.024l1.703,0.793
		l0.98,0.263l3.141,0.183l0.517-0.07l1.008-0.305l0.542-0.07l0.279-0.088l0.2-0.203l0.162-0.218l0.164-0.138l0.257-0.045
		l0.559,0.061l0.268-0.016l0.983-0.502L454,37.222l0.846-0.588l2.237-0.71l1.212-0.141l1.012,0.184l0.932,0.598l0.413,0.069
		l0.283-0.036l0.564-0.152l0.915-0.028l0.21,0.028l-0.008,0.176l-0.04,0.929l-0.101,0.441l-0.203,0.445l-0.759,1.048l-0.648,0.732
		l-0.446,0.195l-0.346,0.082l-0.243,0.131l-0.049,0.252l0.065,0.241l0.146,0.283l0.22,0.349l0.438,0.494l0.028,0.235l-0.154,0.264
		l-0.447,0.455l-0.255,0.343l-0.308,0.286l-0.304,0.111l-0.52-0.03l-0.827-0.305l-0.284-0.069l-0.263,0.061l-0.24,0.358
		l-0.142,0.309l-0.207,0.284l-0.255,0.072l-0.227-0.041l-0.142-0.132l-0.117-0.173l-0.17-0.129l-0.236-0.03l-0.243,0.023
		l-0.223,0.061l-0.194,0.103l-0.166,0.272l-0.073,0.233l-0.049,1.079l-1.765,0.331l-0.389,0.004l-0.243-0.1l-0.093-0.229
		l0.085-0.286l0.194-0.352l0.723-0.999l0.105-0.296l-0.028-0.239l-0.121-0.194l-0.215-0.146l-0.508-0.236l-0.304-0.084l-0.328-0.045
		l-0.423,0.11l-1.009,0.445l-0.795,0.061l-1.582-0.379l-0.547,0.344l-0.231,0.067l-0.269-0.019l-1.244-0.573l-0.119-0.143
		l-0.093-0.187l-0.259-0.201l-0.454-0.099l-0.803,0.038l-0.94,0.166l-1.513,0.72l-1.456-1.841L439.986,41.484z"/>
                     <path :style="city_ids.includes(16) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(16)"
                           @mousemove="onMove($event)" class="st0" d="M435.063,39.124l0.766,0.106l0.881,0.546l0.683,0.77l0.727,0.636l1.002,0.334l0.866-0.032l0.022,0.033
		l1.456,1.841l-1.253,0.619l-1.241,0.241l-0.308,0.177l-0.3,0.09l-0.358,0.02l-0.389-0.131l-0.308-0.198l-0.158-0.328l-0.057-0.18
		l-0.008-0.204l0.004-0.189l-0.012-0.143l-0.113-0.224l-0.101-0.13l-0.747-0.233l0.04-0.759l-0.069-0.094l-0.162-0.118l-1.03-0.044
		l-0.251-0.095l-0.065-0.132l0.065-0.179l0.109-0.161l0.093-0.171l0.012-0.669l0.199-0.761L435.063,39.124z"/>
                     <path :style="city_ids.includes(42) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(42)"
                           @mousemove="onMove($event)" class="st0" d="M401.508,47.85l0.408-0.024l0.266-0.068l0.613-0.298l0.289-0.079l0.23,0.036l0.561,0.163l0.298,0.004
		l1.429-0.393l1.47-0.09l1.219,0.261l0.323,0.02l2.276-0.278l6.531-1.966l0.517-0.22l0.574-0.035l0.568,0.146l0.485,0.313
		l0.166,0.292l0.051,0.297l0.102,0.228l0.313,0.093l0.281,0.034l0.536,0.154l0.255,0.034l3.756-0.222l0.842-0.286l2.471-1.957
		l1.71-0.903l0.672-0.585l0.651-0.962l0.164-0.446l0.674-0.677l1.501-1.102l1.148-0.238l0.204,0.029l-0.007,0.24l-0.199,0.761
		l-0.012,0.669l-0.093,0.171l-0.109,0.161l-0.065,0.179l0.065,0.132l0.251,0.095l1.03,0.044l0.162,0.118l0.069,0.094l-0.04,0.759
		l-0.012,0.409l0.085,0.265l0.053,0.086l0.028,0.07l0.024,0.09l0.028,0.064l0.024,0.074l0.02,0.11l0.008,0.106l-0.028,0.154
		l-0.105,0.131l-0.186,0.114l-0.527,0.087l-0.15,0.053l-0.151,0.111l-0.182,0.218l-0.211,0.147l-0.369,0.073l-3.001-0.083
		l-0.255,0.073l-0.113,0.22l-0.045,0.229l-0.138,0.284l-0.35,0.308l-1.606,0.804l-0.498,0.171l-0.462,0.267l-0.471,0.366
		l-1.205,1.15l-0.417,0.269l-0.15,0.266l-0.069,0.098l-0.04,0.261l-0.073,0.312l-0.203,0.322l-0.3,0.215l-0.528,0.118l-0.944,0.03
		l-0.253-0.031l-0.762-0.212h-0.198l-0.203,0.177l-0.151,0.182l-0.259,1.257l-4.987,0.208l-3.715-0.783l-0.62,0.011l-1.764,0.616
		l-0.957,0.183l-0.304,0.104l-0.686-0.044l-0.802-0.28l-3.598-0.397l-0.421,0.013l-0.271,0.223l-0.194,0.767l-0.15,0.299
		l-0.244,0.244l-0.28,0.189l-0.591,0.317l-0.263,0.081l-0.219-0.033l-0.723-1.49l-0.86-0.961l0.107-0.583l0.713-1.159l0.259-0.598
		L401.508,47.85z"/>
                     <path :style="city_ids.includes(2) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(2)"
                           @mousemove="onMove($event)" class="st0" d="M376.405,54.654l0.336-0.028l0.404-0.198l0.876-0.613l1.633-0.448l0.413-0.317l0.27-0.3l0.6-0.414l0.296-0.293
		l0.115-0.252l0.068-0.28l0.109-0.293l0.242-0.284l0.444-0.189l1.163-0.283l0.985-0.651l1.887-0.008l0.572-0.204l1.042-0.569
		l0.625-0.114l1.119,0.026l0.523-0.057l0.595-0.192l1.527-0.773l0.549-0.095l0.478,0.09l1.138,0.421l0.608,0.136l0.615-0.031
		l1.074-0.318l4.796-0.275l-0.31,1.042l-0.259,0.598l-0.713,1.159l-0.107,0.583l-0.446,0.599l-1.861,1.42l-0.166,0.432l0.041,0.531
		l0.389,1.021l0.344,0.607l0.536,0.715l0.049,0.315l-0.272,0.849l0.004,0.285l0.239,0.141l0.255,0.058l0.276,0.208l0.113,0.336
		v0.747l-0.186,0.337l-0.263,0.189l-0.336,0.114l-0.265,0.146l-0.093,0.3l0.093,0.374l0.423,0.648l0.361,0.318l0.6,0.328
		l0.139,0.195l0.04,0.826l0.166,0.556l0.847,1.71l0.613,0.613l0.174,0.305l0.053,0.272l-0.126,0.304l-0.122,0.425l0.077,0.606
		l-0.694,0.15l-0.3-0.063l-0.945-0.439l-0.689-0.417l-0.158-0.147l-0.101-0.14l-0.065-0.145l-0.077-0.327l-0.028-0.172l-0.061-0.179
		l-0.155-0.208l-0.656-0.542l-0.235-0.027l-0.13,0.055l-0.053,0.083l-0.024,0.049l-0.028,0.073l-0.24,0.402l-0.434,0.4l-0.097,0.167
		l-0.057,0.621l-0.089,0.237l-0.113,0.187l-0.166,0.124l-1.136,0.419l-0.186,0.132l-0.012,0.191l0.097,0.227l0.073,0.263
		l-0.077,0.213l-0.82,0.508l-0.239,0.194l-0.069,0.198l-0.016,0.165l0.113,0.386l-0.904-0.374l-0.253-0.167l-0.292-0.317
		l-0.259-0.538l-0.15-0.227l-0.421-0.533l-0.207-0.389l-0.569-1.298l-0.154-0.17l-0.219-0.102l-0.275,0.051l-2.478,0.743
		l-0.413-0.05l-0.496-0.17l-0.952-0.642l-0.405-0.205l-0.24-0.165l-0.182-0.234l-0.158-0.359l-0.312-0.537l-0.162-0.155l-0.186-0.1
		l-0.953-0.072l-0.994,0.085l-0.263-0.066l-0.215-0.16l-0.721-1.074l-0.058-0.299l0.008-0.232l0.204-0.455l0.036-0.214l-0.02-0.249
		l-0.479-1.344l-0.085-0.314l-0.138-0.91l-0.073-0.266l-0.284-0.12l-0.429,0.016l-1.557,0.412l-1.055,0.42l-0.421,0.027
		l-0.504-0.033l-0.802-0.362l-0.045-0.661l0.855-2.545l0.105-0.441l0.057-0.594l-0.02-0.494L376.405,54.654z"/>
                     <path :style="city_ids.includes(27) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(27)"
                           @mousemove="onMove($event)" class="st0" d="M354.085,72.401l0.8-0.18l1.165-0.61l1.083-0.798l0.825-0.813l0.926-1.889l0.267-0.813l0.885-2.041
		l0.219-0.748l0.036-0.363l0.091-0.2l0.217-0.157l0.259-0.141l0.221-0.163l1.259-1.342l0.453-0.324l1.672-0.579l0.287-0.205
		l0.121-0.215l0.574-0.371l0.215-0.198l0.091-0.286l0.125-0.767l0.123-0.274l0.464-0.256l0.638-0.133l1.148-0.054l0.6-0.116
		l0.442-0.293l0.389-0.368l1.921-1.312l0.991-0.294l0.451-0.4l0.402-0.446l0.44-0.312l0.502-0.121l2.016-0.168l0.003,0.166
		l0.02,0.494l-0.057,0.594l-0.105,0.441l-0.855,2.545l0.045,0.661l-0.585,0.643l-0.292,0.533l-0.308,0.425l-0.933,0.873
		l-0.178,0.285l-0.288,0.691l-0.211,0.246l-0.357,0.192l-0.678,0.226l-0.243,0.15l-0.142,0.187l-0.057,0.379l0.016,0.741
		l-0.032,0.226l-0.073,0.254l-0.028,0.32l-0.004,0.304l-0.057,0.235l-0.126,0.154l-0.305,0.154l-0.235,0.057l-0.721,0.078
		l-0.231-0.03l-0.249,0.071l-0.279,0.189l-0.336,0.652l-0.142,0.364l-0.077,0.375l0.016,0.168l0.081,0.105l0.207,0.116l0.105,0.075
		l0.065,0.069l0.02,0.088l-0.012,0.129l-0.369,1.65l-0.093,0.246l-0.19,0.296l-0.289,0.349l-0.535,0.514l-0.381,0.266l-0.389,0.106
		l-0.447-0.052l-0.227,0.002l-0.194,0.058l-0.308,0.166l-0.32,0.226l-0.816,0.751l-0.271,0.379l-0.324,0.243l-0.415,0.396
		l-0.17,0.515l-1.035,0.014l-0.211,0.061l-0.296,0.125l-0.142,0.111l-0.239,0.135l-0.097-0.018l-0.101-0.127l-0.413-1.358
		l-0.131-0.296l-0.239-0.42l-0.219-0.142l-0.142-0.042l-0.235,0.125l-0.767,0.525l-0.774,0.331l-1.218,0.355l-0.51,0.082
		l-0.235-0.026l-0.141-0.031l-0.103-0.086l-0.127-0.085l-1.008-0.49l-0.178-0.202l-0.06-0.199l0.036-0.169l0.004-0.15l-0.058-0.11
		l-0.065-0.083l-0.072-0.078l-0.076-0.113l-0.07-0.132l-0.039-0.12l-0.026-0.215L354.085,72.401z"/>
                     <path :style="city_ids.includes(31) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(31)"
                           @mousemove="onMove($event)" class="st0" d="M328.142,77.73l0.617-0.454l0.802-0.759l0.305-0.497l0.195-0.199l0.948-0.225l0.498-0.327l0.421-0.369
		l0.399-0.259l0.617-0.189l0.325,0.028l0.145,0.271l0.138,0.131l0.309-0.133l0.995-0.591l0.639-1.102l0.61-0.173l0.213,0.123
		l0.194,0.224l0.234,0.216l0.344,0.097l0.262,0.037l0.51,0.162l0.297,0.039l0.31,0.097l0.141,0.236l0.119,0.283l0.232,0.244
		l0.257,0.063l0.995-0.063l0.472-0.118l0.532-0.257l0.438-0.372l0.316-0.912l0.319-0.052l0.361,0.11l0.27,0.041l0.274-0.165
		l0.183-0.219l1.212-2.568l0.026-0.346l-0.062-0.089l-0.05-0.201l-0.013-0.214l0.035-0.139l0.149-0.039l0.151,0.081l0.134,0.114
		l0.1,0.046l0.264-0.018l0.544,0.05l0.264-0.032l1.095-0.534l0.721-0.217l0.323,0.201l0.123,0.364l0.281,0.176l0.301,0.094
		l0.19,0.116l0.089,0.336l-0.159,0.441l0.07,0.344l0.428,0.47l0.683,0.346l1.865,0.396l1.123,0.48l0.554,0.11l0.267-0.06
		l-0.018,0.162l-1.07,1.139l-1.261,1.717l-0.444,0.369l-0.444,0.191l-0.59-0.048l-0.261,0.008l-0.384,0.07l-0.462,0.234
		l-0.257,0.321l-0.254,0.718l-0.357,0.495l-0.386,0.38l-1.487,1.15l-0.29,0.385l-0.157,0.347l-0.141,0.598l-0.07,0.184l-0.146,0.295
		l-0.194,0.32l-0.269,0.241l-0.641,0.206l-0.316,0.138l-0.151,0.109l-0.065,0.114l-0.019,0.177l0.02,0.192l-0.016,0.532
		l-0.219,0.464l-0.242,0.252l-0.602,0.378l-0.089,0.273l-0.267-0.012l-0.132,0.07l-0.223,0.159l-0.17,0.32l-0.123,0.369
		l-0.213,1.288l-0.135,0.379l-0.197,0.359l-0.274,0.323l-0.263,0.218l-0.273,0.174l-0.335,0.116l-0.261-0.084l-0.242-0.122
		l-0.119-0.993l0.448-0.45l0.004-0.257l-0.08-0.262l-0.162-0.248l-0.057-0.172l0.046-0.18l0.169-0.147l0.566-0.408l0.094-0.363
		l-0.124-0.409l-0.997-1.27l-0.446-0.452l-0.679-0.206l-0.666,0.001L332.12,83.7l-0.474-0.04l-0.329-0.109l-0.592-0.371
		l-0.269-0.075l-0.17,0.099l-0.176,0.296l-0.216,0.554l-0.272,0.36l-0.244,0.113l-0.209-0.147l-0.093-0.27l-0.033-0.365l0.069-0.594
		l0.153-0.389l0.216-0.334l0.914-0.746l0.212-0.254l0.18-0.285l-0.042-0.254l-0.234-0.222l-0.756-0.36l-0.858-0.76l-0.732-1.756
		L328.142,77.73z"/>
                     <path :style="city_ids.includes(46) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(46)"
                           @mousemove="onMove($event)" class="st0" d="M321.461,87.464l0.079-0.074l0.687-0.869l0.493-0.141l0.496-0.347l0.423-0.43l0.285-0.395l0.223-0.641
		l0.31-1.522l0.268-0.577l0.304-0.499l0.917-2.404l0.183-0.332l0.308-0.146l0.615-0.034l0.136-0.154l0.674-0.964l0.279-0.204
		l0.024,0.061l0.732,1.756l0.858,0.76l0.756,0.36l0.234,0.222l0.042,0.254l-0.18,0.285l-0.212,0.254l-0.914,0.746l-0.216,0.334
		l-0.153,0.389l-0.069,0.594l0.033,0.365l0.093,0.27l0.209,0.147l0.244-0.113l0.272-0.36l0.216-0.554l0.176-0.296l0.17-0.099
		l0.269,0.075l0.592,0.371l0.329,0.109l0.474,0.04l5.572-1.448l0.666-0.001l0.679,0.206l0.446,0.452l0.997,1.27l0.124,0.409
		l-0.094,0.363l-0.566,0.408l-0.169,0.147l-0.046,0.18l0.057,0.172l0.162,0.248l0.08,0.262l-0.004,0.257l-0.448,0.45l-2.216,0.44
		l-0.379,0.316l-0.818,1.11l-0.389,0.252l-0.44,0.055l-0.397,0.007l-0.61,0.101l-0.289,0.206l-0.166,0.361l0.066,0.374l0.162,0.468
		l0.082,0.543l-0.072,0.63l-0.324,0.644l-0.384,0.295l-0.462,0.114l-0.424,0.048l-0.519,0.253l-0.378,0.396l-0.71,1.211
		l-1.248-0.317l-5.837-3.143l-0.189-0.011l-0.181,0.012l-0.321,0.088l-0.141,0.071l-0.184,0.056l-0.213,0.033l-0.322-0.012
		l-0.176-0.083l-0.119-0.12l-0.043-0.159l-0.026-0.34l-0.063-0.293l0.051-0.128l0.346-0.27l0.084-0.108l0.039-0.092l0.031-0.146
		l0.036-0.748l0.084-0.374l0.024-0.196l-0.034-0.2l-0.14-0.255l-0.157-0.134l-0.189-0.06l-0.184,0.007l-0.186,0.037l-0.099-0.078
		l-0.036-0.113l-0.024-0.3L321.461,87.464z"/>
                     <path :style="city_ids.includes(19) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(19)"
                           @mousemove="onMove($event)" class="st0" d="M482.528,53.291l1.82,0.13l0.421-0.068l0.565-0.159l0.369-0.216l0.365-0.325l0.194-0.279l0.081-0.337
		l0.016-0.327l0.105-0.321l0.261-0.309l0.478-0.259l0.178-0.215l-0.012-0.213l-0.207-0.22l-0.613-0.508l-0.117-0.152l-0.053-0.091
		l-0.004-0.066l0.04-0.069l0.172-0.212l1.143-0.654l0.073,0.01l0.138,0.039l0.232,0.092l0.296,0.076l0.442-0.04l0.243-0.213
		l0.114-0.289l0.049-0.403l0.101-0.338l0.309-0.387l0.349-0.091l0.308-0.018l0.621,0.148l0.259,0.116l0.199,0.149l0.385,0.376
		l0.783,0.616l0.15,0.226l0.02,0.229l-0.113,0.259l-0.235,0.221l-0.273,0.189l-0.057,0.058l-0.069,0.129l0.041,0.18l0.423,0.586
		l0.19,0.322l0.109,0.358l-0.004,0.339l-0.182,0.727l0.004,0.273l0.515,0.52l0.385,0.263l0.868,0.177l0.567-0.082l0.682-0.257
		l0.203-0.293l0.057-0.42l-0.02-0.364l0.097-0.412l0.154-0.277l0.154-0.183l0.255-0.184l0.852-0.404l0.962-0.203l0.49-0.259
		l0.701-0.474l0.138-0.121l0.073-0.093l0.078-0.197l0.004-0.227l-0.061-0.282l-0.086-0.267l-0.049-0.266l-0.012-0.225l0.036-0.433
		l0.049-0.208l0.074-0.187l0.353-0.567l0.595,0.213l0.138,0.568l0.036,0.419l0.158,0.497l0.249,0.261l0.304,0.163l0.28,0.049
		l0.312-0.003l0.292-0.094l0.228-0.161l0.417-0.435l0.239-0.179l0.373-0.152l0.284,0.061l0.662,0.454l0.336,0.094l0.324,0.008
		l1.614-0.605l0.394,0.414l0.146,0.198l0.19,0.35l0.105,0.358l0.02,0.543l-0.142,0.956l0.045,0.516l0.259,0.41l0.381,0.218
		l0.508,0.07l0.502-0.008l0.478,0.044l0.394,0.337l0.251,0.577l0.081,1.254l-0.044,2.079l-0.174,0.777l-0.028,0.641l0.324,0.499
		l0.393,0.122l0.475-0.025l0.361,0.074l0.211,0.316l-0.117,0.579l-0.251,0.338l-0.293,0.253l-0.219,0.27l-0.012,0.351l0.273,0.475
		l0.316,0.328l0.17,0.288l0.057,0.197l0.024,0.402l0.101,0.231l0.304,0.353l0.417,0.216l0.362,0.457l0.17,0.65l-0.28,3.119
		l0.154,0.502l-0.049,0.987l-0.779,0.543l-0.036,0.129l0.012,0.276l0.105,0.272l-0.004,0.403l-0.251,0.107l-0.486-0.132
		l-1.095-0.896l-0.616-0.403l-0.868-0.266l-0.596,0.041l-0.568,0.206l-0.855,0.544l-0.37,0.292l-0.271,0.307l-0.19,0.362
		l-0.057,0.347l0.122,0.297l0.259,0.194l0.419,0.145l0.344,0.252l0.17,0.361l-0.393,0.903l-0.273,0.382l-0.357,0.078l-1.047-0.45
		l-0.316-0.226l-0.271-0.251l-0.271-0.189l-0.686-0.257l-0.438-0.247l-0.47-0.135l-0.361,0.155l-0.208,0.336l-0.158,0.45
		l-0.199,0.401l-0.47,0.247l-1.326,0.963l-0.207,0.25l-0.121,0.299l-0.061,0.363l-0.089,0.946l-0.134,0.522l-0.204,0.43
		l-0.401,0.472l-0.385,0.199l-0.365,0.105l-0.779-0.017l-4.011-1.001l-0.701-0.424l-0.397-0.409l-0.192-0.664l-0.158-0.234
		l-1.812-0.747l-0.571-0.362l-0.362-0.316l-0.142-0.192l-0.154-0.299l0.012-1.403l0.101-0.276l0.203-0.356l0.285-0.112l1.175-0.023
		l0.317-0.107l0.142-0.278l0.008-0.452l-0.093-1.34l0.024-0.75l0.186-0.93l0.227-0.557l0.567-1.128l0.101-0.544l0.062-0.611
		l0.113-0.286l0.276-0.433l0.02-0.258l-0.243-0.654l-0.097-0.415l0.085-0.252l0.211-0.147l0.389-0.1l0.434-0.217l0.431-0.545
		l0.207-0.478l0.077-0.373l-0.016-0.541l-0.162-0.243l-0.231-0.021l-0.277,0.23l-0.203,0.124l-0.203-0.067l-0.105-0.351
		l-0.049-0.434l-0.085-0.419l-0.134-0.245l-0.263-0.133l-0.354,0.1l-0.373,0.239l-0.425,0.185l-0.482-0.098l-0.475-0.427
		l-0.612-1.175l-0.259-0.403l-0.293-0.232l-0.733-0.308l-0.739-0.152l-0.19-0.013l-0.223,0.017l-1.229,0.412l-0.786,0.09
		l-0.419,0.003l-1.159-0.178l-0.386,0.012l-0.45-0.061l-0.446-0.349l-0.373-0.526l-0.123-0.415l0.028-0.381l0.078-0.245
		L482.528,53.291z"/>
                     <path :style="city_ids.includes(34) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(34)"
                           @mousemove="onMove($event)" class="st0" d="M482.528,53.291l-0.308,0.448l-0.078,0.245l-0.028,0.381l0.123,0.415l0.373,0.526l0.446,0.349l0.45,0.061
		l0.386-0.012l1.159,0.178l0.419-0.003l0.786-0.09l1.229-0.412l0.223-0.017l0.19,0.013l0.739,0.152l0.733,0.308l0.293,0.232
		l0.259,0.403l0.612,1.175l0.475,0.427l0.482,0.098l0.425-0.185l0.373-0.239l0.354-0.1l0.263,0.133l0.134,0.245l0.085,0.419
		l0.049,0.434l0.105,0.351l0.203,0.067l0.203-0.124l0.277-0.23l0.231,0.021l0.162,0.243l0.016,0.541l-0.077,0.373l-0.207,0.478
		l-0.431,0.545l-0.434,0.217l-0.389,0.1l-0.211,0.147l-0.085,0.252l0.097,0.415l0.243,0.654l-0.02,0.258l-0.276,0.433l-0.113,0.286
		l-0.062,0.611l-0.101,0.544l-0.567,1.128l-0.227,0.557l-0.186,0.93l-0.024,0.75l0.093,1.34l-0.008,0.452l-0.142,0.278l-0.317,0.107
		l-1.175,0.023l-0.285,0.112l-0.203,0.356l-0.101,0.276l-0.012,1.403l-0.989-0.048l-0.443-0.175l-0.608-0.386l-0.564-0.566
		l-0.251-0.149l-0.401-0.069l-0.304-0.088l-0.324-0.197l-0.216-0.223l-0.587-0.842l-0.247-0.229l-0.28-0.117l-0.435,0.013
		l-0.936,0.208l-1.038,0.035l-0.373,0.054l-0.771,0.209l-2.011,0.926l-0.555,0.152l-0.548,0.058l-2.656-0.062l-0.43-0.075
		L473.788,71l-0.483-0.536l-0.077-0.346l0.105-0.325l0.783-0.733l0.223-0.307l0.138-0.326l0.126-0.501l0.081-0.11l0.134-0.07
		l0.528-0.068l0.279-0.085l0.101-0.137l-0.02-0.199l-0.105-0.221l-0.255-0.415l-0.126-0.282l-0.198-0.594l-0.142-0.556l-0.082-0.224
		l-0.122-0.209l-0.17-0.089l-0.304,0.007l-0.527,0.214l-0.978,0.686l-0.539,0.264l-0.658,0.037l-0.879-0.082l-3.415-0.743
		l-0.802,0.083l-0.358,0.155l-0.182,0.169l-0.04,0.13l-0.028,0.04l-0.097,0.085l-0.195,0.035l-0.348-0.02l-0.638-0.144l-0.036-0.04
		l-0.069-0.293l-0.089-0.212l-0.117-0.178l-0.409-0.215l0.243-0.549l0.17-0.18l0.795-0.683l0.369-0.481l0.284-0.642l0.304-1.17
		l0.24-0.552l0.373-0.51l1.213-1.026l0.587-0.675l1.229-1.957l1.302-1.655l0.535,0.683l0.361,1.672l0.459,0.951l0.397,0.398
		l0.547,0.105l1.753-0.092l0.397-0.072l0.924-0.353l1.522-0.293l0.259-0.176l0.069-0.326l-0.097-0.358l-0.065-0.372l0.012-0.447
		l0.113-0.954l0.118-0.303l0.211-0.205l0.893-0.433l0.413-0.279l0.316-0.293l0.71-1.134l0.389-0.326l0.267-0.126l0.235-0.005
		l0.182,0.111l0.154,0.209l0.39,0.888l0.049,0.085L482.528,53.291z"/>
                     <path :style="city_ids.includes(10) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(10)"
                           @mousemove="onMove($event)" class="st0" d="M470.905,54.566l-1.302,1.655l-1.229,1.957l-0.587,0.675l-1.213,1.026l-0.373,0.51l-0.24,0.552l-0.304,1.17
		l-0.284,0.642l-0.369,0.481l-0.795,0.683l-0.17,0.18l-0.243,0.549l-1.006,2.65l-0.154,1.221l0.057,0.451l-0.008,0.501l-0.174,0.287
		l-0.336,0.128l-0.438-0.14l-0.986-0.533l-0.356-0.098l-0.346,0.032l-0.405,0.183l-0.871,0.573l-0.508,0.167l-0.571-0.027
		l-0.512-0.226l-0.385-0.342l-0.292-0.343l-0.255-0.247l-0.3-0.177l-0.329-0.131l-0.304-0.158l-0.523-0.502l-0.113-0.069
		l-0.759-0.074l-2.039,0.306l-1.294-2.472l-0.381-1.668l-0.069-0.919l0.016-0.852l0.304-2.83l-0.02-0.908l-0.101-0.616l-0.406-1.309
		l-0.053-0.573l0.012-0.323l0.123-0.191l0.498-0.471l0.186-0.324l-0.057-0.34l-0.45-0.822l-0.073-0.57l-0.012-0.649l-0.13-0.354
		l-0.261-0.188l-0.324-0.073l-0.632-0.254l-0.231-0.005l-0.244,0.067l-0.851,0.368l-0.251,0.201l-0.374,0.44l-0.304-0.067
		l-0.458-0.332l-0.617-0.822l-0.494-0.502l-0.543-0.35l-1.537-0.669l0.28-0.78l0.288-0.633l0.19-0.285l0.255-0.26l0.358-0.186
		l2.815-0.536l0.344-0.139l0.288-0.206l0.227-0.269l0.434-0.679l0.159-0.161l0.296-0.193l0.008-0.273l-0.057-0.359l-0.804-1.499
		l1.582,0.379l0.795-0.061l1.009-0.445l0.423-0.11l0.328,0.045l0.304,0.084l0.508,0.236l0.215,0.146l0.121,0.194l0.028,0.239
		l-0.105,0.296l-0.723,0.999l-0.194,0.352l-0.085,0.286l0.093,0.229l0.243,0.1l0.389-0.004l1.765-0.331l0.737,1.296l0.795,0.684
		l0.685,0.465l0.86,0.851l0.977,0.578l1.237,0.458l6.538,0.265l0.413-0.101l0.836-0.367l0.385,0.02l1.107,0.319l0.474,0.005
		l1.091-0.259l0.519,0.174l0.247,0.534l0.536,0.667l0.093,0.317l-0.032,0.267l-0.089,0.225l-0.081,0.324l-0.028,0.396l0.081,0.485
		l-0.061,0.246l-0.151,0.164l-0.328,0.159l-0.328,0.235L470.905,54.566z"/>
                     <path :style="city_ids.includes(9) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(9)"
                           @mousemove="onMove($event)" class="st0" d="M447.935,43.246l0.804,1.499l0.057,0.359l-0.008,0.273l-0.296,0.193l-0.159,0.161l-0.434,0.679l-0.227,0.269
		l-0.288,0.206l-0.344,0.139l-2.815,0.536l-0.358,0.186l-0.255,0.26l-0.19,0.285l-0.288,0.633l-0.28,0.78l-0.99,0.247l-0.656,0.044
		L440.77,50.2l-0.219,0.236l-0.13,0.371l-0.19,0.38l-0.531,0.502l-0.658,0.339l-0.921,0.349l-1.272,0.832l-0.739,0.595l-0.442,0.244
		l-0.308,0.102l-0.346-0.047l-0.417-0.193l-0.421-0.378l-0.271-0.504l-0.216-0.496l-0.223-0.323l-0.365-0.07l-0.292,0.22
		l-0.601,0.679l-0.381,0.321l-0.413,0.274l-0.544,0.132l-0.502-0.003l-0.587-0.086l-2.588,0.228l-0.592-0.072l-0.22-0.941
		l-0.126-0.131l-0.117-0.037l-0.652,0.356l-0.747,0.141l-1.294,0.508l-0.466,0.075l-0.223-0.218l-0.069-0.413l-0.081-0.119
		l-0.255-0.148l-0.154-0.144l-0.131-0.169l-0.296-0.628l0.259-1.257l0.151-0.182l0.203-0.177h0.198l0.762,0.212l0.253,0.031
		l0.944-0.03l0.528-0.118l0.3-0.215l0.203-0.322l0.073-0.312l0.04-0.261l0.069-0.098l0.15-0.266l0.417-0.269l1.205-1.15l0.471-0.366
		l0.462-0.267l0.498-0.171l1.606-0.804l0.35-0.308l0.138-0.284l0.045-0.229l0.113-0.22l0.255-0.073l3.001,0.083l0.369-0.073
		l0.211-0.147l0.182-0.218l0.151-0.111l0.15-0.053l0.527-0.087l0.186-0.114l0.105-0.131l0.028-0.154l-0.008-0.106l-0.02-0.11
		l-0.024-0.074l-0.028-0.064l-0.024-0.09l-0.028-0.07l-0.053-0.086l-0.085-0.265l0.012-0.409l0.747,0.233l0.101,0.13l0.113,0.224
		l0.012,0.143l-0.004,0.189l0.008,0.204l0.057,0.18l0.158,0.328l0.308,0.198l0.389,0.131l0.358-0.02l0.3-0.09l0.308-0.177
		l1.241-0.241l1.253-0.619l1.513-0.72l0.94-0.166l0.803-0.038l0.454,0.099l0.259,0.201l0.093,0.187l0.119,0.143l1.244,0.573
		l0.269,0.019l0.231-0.067L447.935,43.246z"/>
                     <path :style="city_ids.includes(11) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(11)"
                           @mousemove="onMove($event)" class="st0" d="M401.815,68.061l2.404,0.721l0.783,0.835l0.202,0.321l0.292,0.153l0.32-0.173l0.475-0.606l0.385-0.097
		l1.265,0.331l0.454-0.078l0.186-0.248l0.117-0.463l0.289-0.325l0.563-0.193l0.966,0.164l1.391,0.051l1.219-0.227l0.682,0.022
		l0.644,0.195l1.237,0.769l1.78,0.67l-1.825,3.958l-0.421,0.361l-0.475,0.218l-0.466,0.082l-0.417,0.164l-0.292,0.307l-0.117,0.346
		l0.057,0.456l0.239,0.555l0.523,0.633l0.45,0.304l0.933,0.383l0.316,0.34l0.17,0.309l-0.296,1.477l-2.351-0.021l-1.213,0.171
		l-0.876,0.35l-0.251-0.008l-0.097-0.236l-0.024-0.317l-0.134-0.32l-0.267-0.125l-2.644,0.096l-2.357,0.53l-2.977,0.126
		l-0.425-0.055l-0.3-0.164l-0.146-0.163l-0.138-0.29l-0.171-0.193l-0.203-0.052l-0.227,0.203l-0.3,0.649l-0.361,0.193l-0.613-0.081
		l-0.669-0.5l-0.856-0.988l-0.45-0.299l-0.644,0.105l-0.5,0.199l-0.361,0.099l-0.239-0.042l-0.17-0.367l-0.101-0.73l-0.115-0.346
		l-0.219-0.265l-0.332-0.247l-1.472-0.673l-0.689-0.13l-0.378-0.188l-0.336-0.106l-0.332,0.056l-0.243,0.346l-0.158,0.459
		l-0.228,0.957l-0.17,0.314l-0.28,0.189l-0.332-0.046l-0.263-0.116l-0.629-0.793l0.407-1.683l0.061-0.575l0.004-1.028l0.109-0.483
		l0.207-0.356l0.425-0.137l0.369,0.077l0.723,0.275l0.369-0.034l0.365-0.215l0.402-0.371l0.705-0.918l0.194-0.807l-0.113-0.386
		l0.016-0.165l0.069-0.198l0.239-0.194l0.82-0.508l0.077-0.213l-0.073-0.263l-0.097-0.227l0.012-0.191l0.186-0.132l1.136-0.419
		l0.166-0.124l0.113-0.187l0.089-0.237l0.057-0.621l0.097-0.167l0.434-0.4l0.24-0.402l0.028-0.073l0.024-0.049l0.053-0.083
		l0.13-0.055l0.235,0.027l0.656,0.542l0.155,0.208l0.061,0.179l0.028,0.172l0.077,0.327l0.065,0.145l0.101,0.14l0.158,0.147
		l0.689,0.417l0.945,0.439l0.3,0.063L401.815,68.061z"/>
                     <path :style="city_ids.includes(44) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(44)"
                           @mousemove="onMove($event)" class="st0" d="M417.472,69.882l-1.78-0.67l-1.237-0.769l-0.644-0.195l-0.682-0.022l-1.219,0.227l-1.391-0.051l-0.966-0.164
		l-0.563,0.193l-0.289,0.325l-0.117,0.463l-0.186,0.248l-0.454,0.078l-1.265-0.331l-0.385,0.097l-0.475,0.606l-0.32,0.173
		l-0.292-0.153l-0.202-0.321l-0.783-0.835l-2.404-0.721l-0.077-0.606l0.122-0.425l0.126-0.304l-0.053-0.272l-0.174-0.305
		l-0.613-0.613l-0.847-1.71l-0.166-0.556l-0.04-0.826l-0.139-0.195l-0.6-0.328l-0.361-0.318l-0.423-0.648l-0.093-0.374l0.093-0.3
		l0.265-0.146l0.336-0.114l0.263-0.189l0.186-0.337v-0.747l-0.113-0.336l-0.276-0.208l-0.255-0.058l-0.239-0.141l-0.004-0.285
		l0.272-0.849l-0.049-0.315l-0.536-0.715l-0.344-0.607l-0.389-1.021l-0.041-0.531l0.166-0.432l1.861-1.42l0.446-0.599l0.86,0.961
		l0.723,1.49l0.219,0.033l0.263-0.081l0.591-0.317l0.28-0.189l0.244-0.244l0.15-0.299l0.194-0.767l0.271-0.223l0.421-0.013
		l3.598,0.397l0.802,0.28l0.686,0.044l0.304-0.104l0.957-0.183l1.764-0.616l0.62-0.011l3.715,0.783l4.987-0.208l0.296,0.628
		l0.131,0.169l0.154,0.144l0.255,0.148l0.081,0.119l0.069,0.413l0.223,0.218l0.466-0.075l1.294-0.508l0.747-0.141l0.652-0.356
		l0.117,0.037l0.126,0.131l0.22,0.941l-0.024,0.402l-0.099,0.233l-0.223,0.358l-0.442,0.453l-0.442,0.288l-0.455,0.168l-1.598,0.037
		l-0.227,0.109l-0.17,0.171l-0.255,0.347l-0.336,0.34l-0.166,0.213l-0.099,0.293l-0.028,0.518l0.065,0.253l0.07,0.157l0.425,0.379
		l0.158,0.259l0.126,0.279l0.199,0.267l0.077,0.071l0.061,0.033l0.101-0.013l0.162-0.075l0.564-0.514l0.191-0.109l0.178-0.008
		l0.442,0.238l0.893,0.002l0.207,0.055l0.154,0.12l0.036,0.239l-0.121,0.323l-0.851,0.8l-0.325,0.599l-0.34,0.846l-0.247,0.424
		l-2.717,1.768l-0.215,0.24l-0.186,0.285l-0.134,0.414l-0.19,0.771l-0.212,0.432l-0.34,0.462l-1.671,1.586L417.472,69.882z"/>
                     <path :style="city_ids.includes(48) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(48)"
                           @mousemove="onMove($event)" class="st0" d="M393.947,70.701l-0.194,0.807l-0.705,0.918l-0.402,0.371l-0.365,0.215l-0.369,0.034l-0.723-0.275l-0.369-0.077
		l-0.425,0.137l-0.207,0.356l-0.109,0.483l-0.004,1.028l-0.061,0.575l-0.407,1.683l-1.029,0.053l-0.54,0.175l-1.375,0.831
		l-0.989,0.331l-1.237,0.807l-0.759,0.017l-0.203,0.11l-0.036,0.216l0.138,0.232l0.194,0.227l0.032,0.237l-0.146,0.078l-0.105-0.003
		l-0.113-0.08l-0.15-0.162l-0.15-0.087l-0.207-0.075l-0.308,0.022l-0.22,0.085l-0.275,0.187l-1.812,1.997l-0.158,0.278l-0.053,0.224
		l0.077,0.487l-0.101,0.354l-0.194,0.17l-0.199,0.075l-0.208-0.008l-0.267-0.053l-0.523-0.176l-1.217-0.227l-1.444-0.654
		l-1.719-0.27l-0.409-0.207l-0.281-0.248l-0.223-0.266l-0.559-0.4l-0.397-0.052l-0.471,0.056l-0.539,0.149l-0.466-0.015
		l-0.471-0.277l-0.814-0.788l-0.686-0.481l-0.592-0.149l-0.605-0.048l-0.458,0.028l-0.928,0.246l-0.378,0.028l-0.64-0.093
		l-0.369-0.329l-0.309-0.52l-0.199-0.62l-0.219-1.211l-0.28-0.888l-0.97-0.874l0.17-0.515l0.415-0.396l0.324-0.243l0.271-0.379
		l0.816-0.751l0.32-0.226l0.308-0.166l0.194-0.058l0.227-0.002l0.447,0.052l0.389-0.106l0.381-0.266l0.535-0.514l0.289-0.349
		l0.19-0.296l0.093-0.246l0.369-1.65l0.012-0.129l-0.02-0.088l-0.065-0.069l-0.105-0.075l-0.207-0.116l-0.081-0.105l-0.016-0.168
		l0.077-0.375l0.142-0.364l0.336-0.652l0.279-0.189l0.249-0.071l0.231,0.03l0.721-0.078l0.235-0.057l0.305-0.154l0.126-0.154
		l0.057-0.235l0.004-0.304l0.028-0.32l0.073-0.254l0.032-0.226l-0.016-0.741l0.057-0.379l0.142-0.187l0.243-0.15l0.678-0.226
		l0.357-0.192l0.211-0.246l0.288-0.691l0.178-0.285l0.933-0.873l0.308-0.425l0.292-0.533l0.585-0.643l0.802,0.362l0.504,0.033
		l0.421-0.027l1.055-0.42l1.557-0.412l0.429-0.016l0.284,0.12l0.073,0.266l0.138,0.91l0.085,0.314l0.479,1.344l0.02,0.249
		l-0.036,0.214l-0.204,0.455l-0.008,0.232l0.058,0.299l0.721,1.074l0.215,0.16l0.263,0.066l0.994-0.085l0.953,0.072l0.186,0.1
		l0.162,0.155l0.312,0.537l0.158,0.359l0.182,0.234l0.24,0.165l0.405,0.205l0.952,0.642l0.496,0.17l0.413,0.05l2.478-0.743
		l0.275-0.051l0.219,0.102l0.154,0.17l0.569,1.298l0.207,0.389l0.421,0.533l0.15,0.227l0.259,0.538l0.292,0.317l0.253,0.167
		L393.947,70.701z"/>
                     <path :style="city_ids.includes(32) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(32)"
                           @mousemove="onMove($event)" class="st0" d="M376.855,115.553l5.648,1.777l1.059,0.846l0.463,0.507l-0.382,3.8l0.028,0.535l0.138,0.349l0.402,0.077
		l4.022-0.24l1.006,0.143l0.498,0.296l0.317,0.495l0.531,1.298l1.391,1.793l1.399,2.861l0.507,0.516l0.496,0.335l1.383,0.603
		l1.138,0.651l4.031,1.148l1.257,0.513l0.864,0.504l0.442,0.354l0.446,0.47l0.458,0.666l1.854,3.884l3.576,5.415l0.312,0.955
		l0.162,1.367l-0.158,1.014l-0.288,0.788l-0.986,1.18l-0.17,0.314l-0.089,0.366l0.065,0.492l0.285,0.556l0.51,0.722l0.239,0.43
		l0.17,0.479l0.276,1.919l0.203,0.72l0.382,0.898l0.62,1.033l2.535,3.143l0.81,1.829l1.464,5.354l0.771,1.495l0.207,0.985
		l0.154,1.431l-0.203,7.464l0.15,1.094l0.82,3.055l0.182,1.057l0.085,1.115l-0.04,1.239l-0.271,1.104l-0.451,1.07l-2.27,3.405
		l-0.353,0.875l-0.089,1.083l0.15,0.902l0.519,1.454l0.122,0.627l-0.073,0.543l-0.45,0.639l-0.593,0.538l-2.201,1.587l-0.779,0.768
		l-27.502,13.991l-4.981,3.202l-2.412,2.044l-9.174,9.781l-14.456-12.645l-5.651-6.517l-0.799-1.643l-0.008-1.184l9.452-27.636
		l0.718-2.286l0.219-1.324l0.019-0.535l-0.032-0.427l-0.065-0.371l-0.089-0.252l-0.076-0.15l-0.024-0.026l-0.288-0.173l-0.336-0.096
		l-0.393-0.062l-0.412-0.13l-0.404-0.27l-0.38-0.657l-0.081-0.667l0.069-0.731l2.234-5.984l0.177-0.664l0.046-0.419l-0.637-5.658
		l0.059-0.643l0.22-1.234l0.488-1.73l1.436-4.846l0.223-1.165l0.12-2.127l-0.585-7.743l0.128-5.654l0.876-6.396l0.049-1.326
		l0.3-0.847l0.267-0.539l0.239-0.375l0.751-0.822l0.964-0.809l0.394-0.437l0.174-0.272l0.065-0.147l0.101-0.309l0.243-0.517
		l0.203-0.296l0.061-0.108l0.142-0.129l0.219-0.135l0.455-0.127l0.514-0.044l1.565,0.044l0.28-0.126l1.079-0.917l0.219-0.229
		l5.714-3.471l0.109-0.105l1.883-1.171l0.875-0.354L376.855,115.553z"/>
                     <path :style="city_ids.includes(20) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(20)"
                           @mousemove="onMove($event)" class="st0" d="M376.855,115.553l-0.735,0.086l-0.875,0.354l-1.883,1.171l-0.109,0.105l-5.714,3.471l-0.219,0.229
		l-1.079,0.917l-0.28,0.126l-1.565-0.044l-0.514,0.044l-0.455,0.127l-0.219,0.135l-0.142,0.129l-0.061,0.108l-0.203,0.296
		l-0.243,0.517l-0.101,0.309l-0.065,0.147l-0.174,0.272l-0.394,0.437l-0.964,0.809l-0.751,0.822l-0.239,0.375l-0.267,0.539
		l-0.3,0.847l-0.439,0.081l-0.563,0.319l-1.245,0.908l-1.153,0.617l-0.147,0.049l-0.147-0.017l-0.12-0.085l-0.045-0.18l-0.004-0.094
		l0.012-0.062l0.022-0.052l0.057-0.207l-0.003-0.161l-0.051-0.331l-0.043-0.911l-0.176-0.628l-0.127-0.155l-0.212-0.139
		l-0.798-0.167l-0.648-0.035l-0.165,0.036l-0.14,0.064l-0.232,0.179l-0.891,0.362l-0.489,0.139l-0.254,0.119l-0.116,0.078
		l-0.112,0.095l-0.833,0.935l-0.921,1.338l-0.219,0.476l-0.082,0.135l-0.115,0.071l-0.168-0.005l-0.219-0.056l-0.176,0.01
		l-0.151,0.051l-0.192,0.157l-0.042,0.073l-0.042,0.119l-0.013,0.143v0.122l0.032,0.264l0.008,0.168l-0.026,0.168l-0.072,0.13
		l-0.094,0.08l-0.13-0.045l-0.143-0.122l-0.456-0.575l-0.095-0.236l0.03-0.306l0.054-0.206l-0.04-0.396l-0.702-1.855l-1.987-3.925
		l-0.155-0.495l-0.127-0.611l0.04-0.393l0.324-0.75l10.815-9.511l0.089-0.16l0.198-0.487l0.146-0.559l0.065-0.435l0.007-0.195
		l-0.064-0.523l-0.039-0.159l-0.124-0.29l-1.388-2.041l-0.866-0.897l-0.887-0.69l-0.208-0.23l-0.123-0.099l-0.155-0.08l-0.148-0.121
		l-0.113-0.182l-0.022-0.371l-0.043-0.228l-0.094-0.154l-0.132-0.077l-0.151-0.038l-0.357-0.014l-0.165,0.015l-0.138-0.004
		l-0.094-0.064l-0.061-0.105l-0.07-0.102l-0.062-0.061l-0.12-0.07l-0.505-0.192l-0.151-0.1l-0.101-0.147l-0.04-0.248l-0.009-0.422
		l-0.043-0.16l-0.085-0.128l-0.244-0.207l-0.143-0.087l-1.026-0.42l-0.162-0.1l-0.107-0.176l0.004-0.153l0.07-0.141l0.094-0.128
		l0.116-0.103l0.128-0.063l0.155,0.007l0.263,0.124l0.134,0.003l0.555-0.254l0.651-0.182l0.163-0.078l0.157-0.138l0.051-0.152
		l-0.035-0.142l-0.095-0.117l-0.119-0.105l-0.266-0.19l-0.096-0.184l-0.032-0.278l0.074-0.849l-0.042-0.222l-0.08-0.129
		l-0.055-0.119l0.039-0.179l0.117-0.223l0.045-0.316l0.088-0.198l0.111-0.12l0.151-0.031l0.167,0.033l0.303,0.125l0.157,0.017
		l0.157-0.066l0.151-0.13l0.161-0.235l0.02-0.183l-0.076-0.133l-0.238-0.239l-0.054-0.201l-0.105-0.577l-0.022-0.251l0.022-0.416
		l0.076-0.226l0.1-0.17l0.111-0.107l0.132-0.079L351,94.299l0.367-0.026l0.148-0.06l0.122-0.099l0.208-0.238l0.12-0.099l0.145-0.056
		l0.186-0.007l0.442,0.051l0.774-0.151l0.097-0.111l0.068-0.15l0.016-0.2l0.062-0.221l0.172-0.272l0.209-0.097l0.15,0.027
		l0.112,0.106l0.089,0.128l0.122,0.292l0.024,0.175l0.013,0.182l-0.024,0.396l0.008,0.184l0.046,0.193l0.146,0.176l0.296,0.206
		l0.393,0.096l0.2,0.127l0.444,0.423l0.525,0.38l0.27,0.007l0.201-0.147l0.236-0.479l0.329-0.3l0.462-0.03l0.728,0.183l0.444-0.048
		l0.34-0.158l0.296-0.058l0.332,0.177l0.313,0.381l0.442,0.889l0.316,0.404l0.629,0.467l0.519,0.064l0.514-0.121l0.439-0.202
		l0.417-0.129l0.835-0.162l0.966-0.449l0.442-0.025l1.083,0.267l0.519,0.017l0.577-0.131l0.454-0.267l0.743-0.625l0.393-0.245
		l3.471-0.306l0.719,0.196l0.016,0.69l0.024,0.087l0.057,0.119l0.081,0.114l0.632,0.393l0.901,0.443l0.531,0.362l0.415,0.408
		l0.531,0.742l0.186,0.379l0.089,0.473l-0.053,0.31l-0.235,0.415l-2.418,2.174l-0.308,0.364l-0.061,0.423l0.024,0.691l0.134,0.991
		l0.524,1.688l4.253,7.437l0.016,0.072l-0.016,0.077l-0.061,0.181l-0.162,0.237l-0.263,0.222l-1.61,0.879L376.855,115.553z"/>
                     <path :style="city_ids.includes(22) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(22)"
                           @mousemove="onMove($event)" class="st0" d="M352.738,93.765l-0.442-0.051l-0.186,0.007l-0.145,0.056l-0.12,0.099l-0.208,0.238l-0.122,0.099l-0.148,0.06
		L351,94.299l-0.154,0.048l-0.132,0.079l-0.111,0.107l-0.1,0.17l-0.076,0.226l-0.022,0.416l0.022,0.251l0.105,0.577l0.054,0.201
		l0.238,0.239l0.076,0.133l-0.02,0.183l-0.161,0.235l-0.151,0.13l-0.157,0.066l-0.157-0.017l-0.303-0.125l-0.167-0.033l-0.151,0.031
		l-0.111,0.12l-0.088,0.198l-0.045,0.316l-0.117,0.223l-0.039,0.179l0.055,0.119l0.08,0.129l0.042,0.222l-0.074,0.849l0.032,0.278
		l0.096,0.184l0.266,0.19l0.119,0.105l0.095,0.117l0.035,0.142l-0.051,0.152l-0.157,0.138l-0.163,0.078l-0.651,0.182l-0.555,0.254
		l-0.134-0.003l-0.263-0.124l-0.155-0.007l-0.128,0.063l-0.116,0.103l-0.094,0.128l-0.07,0.141l-0.004,0.153l0.107,0.176l0.162,0.1
		l1.026,0.42l0.143,0.087l0.244,0.207l0.085,0.128l0.043,0.16l0.009,0.422l0.04,0.248l0.101,0.147l0.151,0.1l0.505,0.192l0.12,0.07
		l0.062,0.061l0.07,0.102l0.061,0.105l0.094,0.064l0.138,0.004l0.165-0.015l0.357,0.014l0.151,0.038l0.132,0.077l0.094,0.154
		l0.043,0.228l0.022,0.371l0.113,0.182l0.148,0.121l0.155,0.08l0.123,0.099l0.208,0.23l0.887,0.69l0.866,0.897l1.388,2.041
		l0.124,0.29l0.039,0.159l0.064,0.523l-0.007,0.195l-0.065,0.435l-0.146,0.559l-0.198,0.487l-0.089,0.16l-10.815,9.511l-0.413-1.148
		l-0.2-0.313l-0.365-0.469l-0.401-0.314l-0.493-0.258l-0.544-0.142l-0.513-0.024l-0.509,0.084l-0.519,0.202l-1.086,0.698
		l-1.642,1.302l-0.602,0.362l-0.672,0.265l-1.199,0.204l-1.984-0.16l-0.598-0.167l-0.513-0.266l-0.409-0.325l-0.5-0.32l-0.284-0.107
		h-0.323l-0.762,0.203l-0.563,0.056l-0.455-0.108l-0.354-0.167l-0.683-0.415l0.651-1.057l1.029-1.092l4.187-3.564l0.416-0.503
		l0.109-0.246l0.086-0.259l0.008-0.215l-0.014-0.138l-0.047-0.188l-0.053-0.119l-0.155-0.265l-0.099-0.131l-0.276-0.292
		l-0.126-0.171l-0.096-0.216l0.039-0.285l0.205-0.244l0.302-0.187l1.147-0.386l0.204-0.098l0.081-0.074l0.089-0.13l0.435-1.235
		l0.508-1.115l0.378-0.65l0.378-0.81l0.07-0.303l-0.009-0.223l-0.216-0.404l-0.093-0.115l-0.13-0.07l-1.671,0.17l-0.679-0.069
		l-0.538-0.279l-0.271-0.569l-0.012-0.757l0.19-1.058l0.047-0.554l-0.07-0.264l-0.282-0.043l-0.35,0.065l-0.343,0.121l-0.213-0.002
		l-0.139-0.109l-0.042-0.278l0.108-0.267l0.381-0.537l0.003-0.158l-0.131-0.152l-0.14-0.055l-0.105-0.02h-0.096l-0.126-0.055
		l-0.155-0.189l-0.173-0.336l-0.17-0.506l-0.03-0.194l0.001-0.423l0.055-0.633l0.3-1.22l0.004-0.004l0.105-0.216l0.282-0.457
		l0.242-0.607l-0.012-0.214l-0.088-0.148l-1.484-1l-0.086-0.035l-0.189-0.101l0.71-1.211l0.378-0.396l0.519-0.253l0.424-0.048
		l0.462-0.114l0.384-0.295l0.324-0.644l0.072-0.63l-0.082-0.543l-0.162-0.468l-0.066-0.374l0.166-0.361l0.289-0.206l0.61-0.101
		l0.397-0.007l0.44-0.055l0.389-0.252l0.818-1.11l0.379-0.316l2.216-0.44l0.119,0.993l0.242,0.122l0.261,0.084l0.335-0.116
		l0.273-0.174l0.263-0.218l0.274-0.323l0.197-0.359l0.135-0.379l0.213-1.288l0.123-0.369l0.17-0.32l0.223-0.159l0.132-0.07
		l0.267,0.012l0.533-0.065l0.598-0.167l0.524-0.209l0.459-0.117l0.132,0.065l0.143,0.204l-0.026,0.464l-0.286,0.459l-0.434,0.572
		l0.124,0.248l0.366,0.275l0.89,0.358l0.543,0.151l0.349,0.012l0.406-0.454l0.185-0.114l0.289,0.025l4.068,1.563l0.201,0.134
		l0.242,0.208l0.011,0.391l-0.147,0.605l-0.088,0.536l-0.062,0.139l-0.081,0.136l-0.057,0.209l-0.03,0.279l0.022,0.428l-0.032,0.196
		l-0.057,0.137l-0.097,0.096l-0.095,0.125l-0.012,0.191l0.076,0.238l0.509,0.514l0.353,0.476L352.738,93.765z"/>
                     <path :style="city_ids.includes(29) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(29)"
                           @mousemove="onMove($event)" class="st0" d="M363.05,75.391l0.97,0.874l0.28,0.888l0.219,1.211l0.199,0.62l0.309,0.52l0.369,0.329l0.64,0.093l0.378-0.028
		l0.928-0.246l0.458-0.028l0.605,0.048l0.592,0.149l0.686,0.481l0.814,0.788l0.471,0.277l0.466,0.015l0.539-0.149l0.471-0.056
		l0.397,0.052l0.559,0.4l0.223,0.266l0.281,0.248l0.409,0.207l1.719,0.27l1.444,0.654l1.217,0.227l-0.134,0.597l0.146,0.207
		l0.271,0.27l0.905,0.551l0.186,0.206l-0.061,0.373l-0.276,0.535l-1.245,1.664l-0.467,0.501l-0.616,0.355l-0.377,0.054l-0.357-0.093
		l-0.374-0.192l-0.94-0.356l-0.415-0.06l-0.259,0.007l-0.361,0.092l-0.381,0.157l-0.378,0.215l-0.361,0.281l-0.357,0.461
		l-0.304,0.575l-0.702,1.67l-1.355,1.818l-0.563,1.542l-0.393,0.245l-0.743,0.625l-0.454,0.267l-0.577,0.131l-0.519-0.017
		l-1.083-0.267l-0.442,0.025l-0.966,0.449l-0.835,0.162l-0.417,0.129l-0.439,0.202l-0.514,0.121l-0.519-0.064l-0.629-0.467
		l-0.316-0.404l-0.442-0.889l-0.313-0.381l-0.332-0.177l-0.296,0.058l-0.34,0.158l-0.444,0.048l-0.728-0.183l-0.462,0.03l-0.329,0.3
		l-0.236,0.479l-0.201,0.147l-0.27-0.007l-0.525-0.38l-0.444-0.423l-0.2-0.127l-0.393-0.096l-0.296-0.206l-0.146-0.176l-0.046-0.193
		l-0.008-0.184l0.024-0.396l-0.013-0.182l-0.024-0.175l-0.122-0.292l-0.089-0.128l-0.112-0.106l-0.15-0.027l-0.209,0.097
		l-0.172,0.272l-0.062,0.221l-0.016,0.2l-0.068,0.15l-0.097,0.111l-0.774,0.151l-0.416-0.946l-0.353-0.476l-0.509-0.514
		l-0.076-0.238l0.012-0.191l0.095-0.125l0.097-0.096l0.057-0.137l0.032-0.196l-0.022-0.428l0.03-0.279l0.057-0.209l0.081-0.136
		l0.062-0.139l0.088-0.536l0.147-0.605l-0.011-0.391l-0.242-0.208l-0.201-0.134l-4.068-1.563l-0.289-0.025l-0.185,0.114
		l-0.406,0.454l-0.349-0.012l-0.543-0.151l-0.89-0.358l-0.366-0.275l-0.124-0.248l0.434-0.572l0.286-0.459l0.026-0.464l-0.143-0.204
		l-0.132-0.065l-0.459,0.117l-0.524,0.209l-0.598,0.167l-0.533,0.065l0.089-0.273l0.602-0.378l0.242-0.252l0.219-0.464l0.016-0.532
		l-0.02-0.192l0.019-0.177l0.065-0.114l0.151-0.109l0.316-0.138l0.641-0.206l0.269-0.241l0.194-0.32l0.146-0.295l0.07-0.184
		l0.141-0.598l0.157-0.347l0.29-0.385l1.487-1.15l0.386-0.38l0.357-0.495l0.254-0.718l0.257-0.321l0.462-0.234l0.384-0.07
		l0.261-0.008l0.59,0.048l0.444-0.191l0.444-0.369l1.261-1.717l1.07-1.139l0.026,0.215l0.039,0.12l0.07,0.132l0.076,0.113
		l0.072,0.078l0.065,0.083l0.058,0.11l-0.004,0.15l-0.036,0.169l0.06,0.199l0.178,0.202l1.008,0.49l0.127,0.085l0.103,0.086
		l0.141,0.031l0.235,0.026l0.51-0.082l1.218-0.355l0.774-0.331l0.767-0.525l0.235-0.125l0.142,0.042l0.219,0.142l0.239,0.42
		l0.131,0.296l0.413,1.358l0.101,0.127l0.097,0.018l0.239-0.135l0.142-0.111l0.296-0.125l0.211-0.061L363.05,75.391z"/>
                     <path :style="city_ids.includes(47) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(47)"
                           @mousemove="onMove($event)" class="st0" d="M411.555,202.233l0.779-0.768l2.201-1.587l0.593-0.538l0.45-0.639l0.073-0.543l-0.122-0.627l-0.519-1.454
		l-0.15-0.902l0.089-1.083l0.353-0.875l2.27-3.405l0.451-1.07l0.271-1.104l0.04-1.239l-0.085-1.115l-0.182-1.057l-0.82-3.055
		l-0.15-1.094l0.203-7.464l-0.154-1.431l-0.207-0.985l9.371-1.754l3.017-1.135l3.184-0.729l5.575-0.568l1.488,0.212l0.674,0.24
		l2.137,1.088l0.783,0.129l0.685-0.118l0.686-0.442l0.519-0.566l0.994-1.352l0.575-0.586l8.038-3.813l0.093-0.078l0.665-0.092
		l1.298,0.093l0.271,0.08l0.69,0.397l0.887,0.102l7.625-1.489l20.961,5.881l-6.257,9.591l-5.135,4.842l-3.75,2.453l-0.654,0.719
		l-0.385,0.526l-0.97,1.723l-1.03,2.94l-2.112,4.04l-2.377,5.618l-1.573,7.304l-6.484,47.564l-2.118,3.815l-3.187,2.877l-1.91,2.233
		l-1.225,1.708l-2.145,8.167l-1.387,1.748l-9.368,5.48l-23.695,2.607l-3.552-31.803l3.353-23.195L411.555,202.233z"/>
                     <path :style="city_ids.includes(3) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(3)"
                           @mousemove="onMove($event)" class="st0" d="M416.941,170.2l-0.771-1.495l-1.464-5.354l-0.81-1.829l-2.535-3.143l-0.62-1.033l-0.382-0.898l-0.203-0.72
		l-0.276-1.919l-0.17-0.479l-0.239-0.43l-0.51-0.722l-0.285-0.556l-0.065-0.492l0.089-0.366l0.17-0.314l0.986-1.18l0.288-0.788
		l0.158-1.014l-0.162-1.367l-0.312-0.955l-3.576-5.415l-1.854-3.884l-0.458-0.666l-0.446-0.47l-0.442-0.354l-0.864-0.504
		l-1.257-0.513l-4.031-1.148l-1.138-0.651l-1.383-0.603l-0.496-0.335l-0.507-0.516l-1.399-2.861l7.98-5.913l0.423-0.513l0.288-0.788
		l0.158-0.647l0.425-0.888l0.662-0.91l1.403-1.552l0.944-0.808l0.694-0.482l3.268-1.016l0.608-0.337l2.782-2.43l2.438-1.446
		l0.393-0.426l0.215-0.356l0.2-0.463l0.231-0.202l0.466-0.168l2.003-0.357l1.116-0.053l0.174,1.693l-0.126,0.396l-0.219,0.509
		l-0.733,0.816l-0.358,0.697l-0.215,1.018l-0.203,1.788l0.061,1.138l0.227,0.837l0.332,0.44l0.561,0.544l0.324,0.4l0.259,0.512
		l0.207,0.758l0.471,2.747l0.178,0.526l0.34,0.536l0.949,1.083l0.401,0.549l0.336,0.604l0.316,0.758l0.085,0.53l-0.057,0.504
		l-0.543,1.679L421.3,129.3l0.004,0.349l0.207,1.741l0.223,0.417l0.394,0.2l0.725,0.009l2.092,0.247l0.901-0.017l0.182-0.038
		l0.093-0.147l0.109-0.759l0.049-0.957l-0.101-3.399l0.04-0.375l0.097-0.256l0.154-0.14l0.281-0.103l2.363-0.4l1.205-0.431
		l0.373-0.217l0.273-0.264l0.247-0.775l0.194-0.399l0.34-0.396l1.966-1.817l0.423-0.291l0.365-0.069l0.3,0.078l0.609,0.351
		l0.255,0.101l0.381,0.052l1.266-0.017l0.591,0.249l0.271,0.325l0.629,1.835l0.336,2.33l0.219,0.729l0.296,0.713l1.871,3.302
		l10.685,12.042l10.122,8.891l0.803,0.871l0.664,0.891l0.868,1.708l1.252,3.327l0.277,1.03l-7.625,1.489l-0.887-0.102l-0.69-0.397
		l-0.271-0.08l-1.298-0.093l-0.665,0.092l-0.093,0.078l-8.038,3.813l-0.575,0.586l-0.994,1.352l-0.519,0.566l-0.686,0.442
		l-0.685,0.118l-0.783-0.129l-2.137-1.088l-0.674-0.24l-1.488-0.212l-5.575,0.568l-3.184,0.729l-3.017,1.135L416.941,170.2z"/>
                     <path :style="city_ids.includes(26) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(26)"
                           @mousemove="onMove($event)" class="st0" d="M415.85,79.774l0.296-1.477l-0.17-0.309l-0.316-0.34l-0.933-0.383l-0.45-0.304l-0.523-0.633l-0.239-0.555
		l-0.057-0.456l0.117-0.346l0.292-0.307l0.417-0.164l0.466-0.082l0.475-0.218l0.421-0.361l1.825-3.958l1.124-1.539l1.671-1.586
		l0.34-0.462l0.212-0.432l0.19-0.771l0.134-0.414l0.186-0.285l0.215-0.24l2.717-1.768l0.247-0.424l0.34-0.846l0.325-0.599l0.851-0.8
		l0.121-0.323l-0.036-0.239l-0.154-0.12l-0.207-0.055l-0.893-0.002l-0.442-0.238l-0.178,0.008l-0.191,0.109l-0.564,0.514
		l-0.162,0.075l-0.101,0.013l-0.061-0.033l-0.077-0.071l-0.199-0.267l-0.126-0.279l-0.158-0.259l-0.425-0.379l-0.07-0.157
		l-0.065-0.253l0.028-0.518l0.099-0.293l0.166-0.213l0.336-0.34l0.255-0.347l0.17-0.171l0.227-0.109l1.598-0.037l0.455-0.168
		l0.442-0.288l0.442-0.453l0.223-0.358l0.099-0.233l0.024-0.402l0.592,0.072l2.588-0.228l0.587,0.086l0.502,0.003l0.544-0.132
		l0.413-0.274l0.381-0.321l0.601-0.679l0.292-0.22l0.365,0.07l0.223,0.323l0.216,0.496l0.271,0.504l0.421,0.378l0.417,0.193
		l0.346,0.047l0.308-0.102l0.442-0.244l0.739-0.595l1.272-0.832l0.921-0.349l0.658-0.339l0.531-0.502l0.19-0.38l0.13-0.371
		l0.219-0.236l0.439-0.206l0.656-0.044l0.99-0.247l1.537,0.669l0.543,0.35l0.494,0.502l0.617,0.822l0.458,0.332l0.304,0.067
		l0.374-0.44l0.251-0.201l0.851-0.368l0.244-0.067l0.231,0.005l0.632,0.254l0.324,0.073l0.261,0.188l0.13,0.354l0.012,0.649
		l0.073,0.57l0.45,0.822l0.057,0.34l-0.186,0.324l-0.498,0.471l-0.123,0.191l-0.012,0.323l0.053,0.573l0.406,1.309l0.101,0.616
		l0.02,0.908l-0.304,2.83l-0.016,0.852l0.069,0.919l0.381,1.668l1.294,2.472l0.016,0.391l0.126,0.682l-0.077,0.295l-0.142,0.299
		l-0.357,0.506l-0.082,0.582l-0.158,0.17l-1.021,0.407l-0.593,0.5l-0.109,0.377l0.069,0.46l0.304,0.777l0.119,0.445l0.049,0.496
		l-0.036,1.057l-0.119,0.42l-0.215,0.203l-0.51-0.411l-0.304-0.347l-0.247-0.411l-0.257-0.559l-0.328-0.587l-1.253-1.684
		l-0.304-0.222l-0.251-0.078l-0.263,0.185l-0.174,0.205l-0.386,1.373l-1.118,0.118l-0.39-0.065l-0.413-0.162l-0.292-0.28
		l-0.211-0.332l-0.336-0.695l-0.22-0.133l-0.271,0.062l-0.369,0.357l-0.284,0.523l-0.374,1.707l-0.227,0.456l-0.279,0.271
		l-0.478,0.274l-2.515,0.452l-0.425-0.125l-0.243-0.234l-0.188-0.868l-0.109-0.306l-0.154-0.19l-0.502-0.361l-0.194-0.289
		l-0.142-0.362l-0.19-0.762l-0.138-0.346l-0.342-0.311l-0.551-0.123l-0.791,0.188l-0.462,0.039l-0.336,0.071l-0.227,0.123
		l-0.17,0.36v0.469l0.097,0.484l0.243,0.895l0.032,0.318l-0.04,0.642l0.008,0.275l0.109,0.937l-0.004,0.445l-0.065,0.379
		l-0.134,0.294l-0.276,0.312l-3.091,2.45l-0.466,0.47l-0.296,0.362l-0.126,0.194l-0.442,0.797l-0.313,0.412l-0.389,0.37
		l-0.421,0.229l-0.451,0.134l-0.442,0.017l-0.446-0.123l-0.377-0.197l-1.988-1.556l-0.061-0.027l-1.29,0.262l-5.016,1.731
		L415.85,79.774z"/>
                     <path :style="city_ids.includes(14) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(14)"
                           @mousemove="onMove($event)" class="st0" d="M389.608,76.955l0.629,0.793l0.263,0.116l0.332,0.046l0.28-0.189l0.17-0.314l0.228-0.957l0.158-0.459
		l0.243-0.346l0.332-0.056l0.336,0.106l0.378,0.188l0.689,0.13l1.472,0.673l0.332,0.247l0.219,0.265l0.115,0.346l0.101,0.73
		l0.17,0.367l0.239,0.042l0.361-0.099l0.5-0.199l0.644-0.105l0.45,0.299l0.856,0.988l0.669,0.5l0.613,0.081l0.361-0.193l0.3-0.649
		l0.227-0.203l0.203,0.052l0.171,0.193l0.138,0.29l0.146,0.163l0.3,0.164l0.425,0.055l2.977-0.126l2.357-0.53l2.644-0.096
		l0.267,0.125l0.134,0.32l0.024,0.317l0.097,0.236l0.251,0.008l0.876-0.35l1.213-0.171l2.351,0.021l0.941,3.869l0.028,3.52
		l0.158,0.255l0.203,0.192l0.474,0.279l0.236,0.079l0.482,0.097l0.182,0.127l0.178,0.293l0.357,0.773l0.147,0.425l0.069,0.458
		l-0.139,0.417l-0.024,0.343l0.094,0.239l0.203,0.215l5.674,4.579l0.328,0.403l0.093,0.358l-0.219,0.259l-0.394,0.204l-1.841,0.515
		l-0.077,0.044l-0.429,0.872l-1.221,3.238l-0.555,0.932l-1.806,2.056l-0.32,2.653l-1.116,0.053l-2.003,0.357l-0.466,0.168
		l-0.231,0.202l-0.2,0.463l-0.215,0.356l-0.393,0.426l-2.438,1.446l-2.782,2.43l-0.608,0.337l-3.268,1.016l-0.694,0.482
		l-0.944,0.808l-1.403,1.552l-0.662,0.91l-0.425,0.888l-0.158,0.647l-0.288,0.788l-0.423,0.513l-7.98,5.913l-1.391-1.793
		l-0.531-1.298l-0.317-0.495l-0.498-0.296l-1.006-0.143l-4.022,0.24l-0.402-0.077l-0.138-0.349l-0.028-0.535l0.382-3.8l-0.463-0.507
		l-1.059-0.846l-5.648-1.777l0.482-0.359l1.61-0.879l0.263-0.222l0.162-0.237l0.061-0.181l0.016-0.077l-0.016-0.072l-4.253-7.437
		l-0.524-1.688l-0.134-0.991l-0.024-0.691l0.061-0.423l0.308-0.364l2.418-2.174l0.235-0.415l0.053-0.31l-0.089-0.473l-0.186-0.379
		l-0.531-0.742l-0.415-0.408l-0.531-0.362l-0.901-0.443l-0.632-0.393l-0.081-0.114l-0.057-0.119l-0.024-0.087l-0.016-0.69
		l-0.719-0.196l-3.471,0.306l0.563-1.542l1.355-1.818l0.702-1.67l0.304-0.575l0.357-0.461l0.361-0.281l0.378-0.215l0.381-0.157
		l0.361-0.092l0.259-0.007l0.415,0.06l0.94,0.356l0.374,0.192l0.357,0.093l0.377-0.054l0.616-0.355l0.467-0.501l1.245-1.664
		l0.276-0.535l0.061-0.373l-0.186-0.206l-0.905-0.551l-0.271-0.27l-0.146-0.207l0.134-0.597l0.523,0.176l0.267,0.053l0.208,0.008
		l0.199-0.075l0.194-0.17l0.101-0.354l-0.077-0.487l0.053-0.224l0.158-0.278l1.812-1.997l0.275-0.187l0.22-0.085l0.308-0.022
		l0.207,0.075l0.15,0.087l0.15,0.162l0.113,0.08l0.105,0.003l0.146-0.078l-0.032-0.237l-0.194-0.227l-0.138-0.232l0.036-0.216
		l0.203-0.11l0.759-0.017l1.237-0.807l0.989-0.331l1.375-0.831l0.54-0.175L389.608,76.955z"/>
                     <path :style="city_ids.includes(28) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(28)"
                           @mousemove="onMove($event)" class="st0" d="M489.149,72.771l0.154,0.299l0.142,0.192l0.362,0.316l0.571,0.362l1.812,0.747l0.158,0.234l0.192,0.664
		l0.397,0.409l0.701,0.424l4.011,1.001l-3.013,2.664l-0.763,0.471l-0.648,0.212l-0.957,0.591l-0.466,0.096l-0.434-0.151
		l-0.411-0.204l-0.466-0.123l-1.561-0.023l-0.604,0.073l-0.423,0.191l-0.215,0.137l-0.219,0.291l-0.097,0.1l-0.608,0.305
		l-0.386-0.065l-0.316-0.159l-0.113-0.098l-0.482-0.576l-0.219-0.188l-0.224-0.128l-0.251-0.051l-0.178,0.089l-0.097,0.201
		l0.008,0.264l0.506,1.906l0.139,1.154l0.049,1.147l-0.073,0.84l-0.305,1.72l-0.186,0.526l-0.425,0.667l-0.767,0.917l-0.993,0.86
		l-0.403,0.491l-0.15,0.354l0.053,0.249l0.089,0.191l0.172,0.164l0.255,0.116l1.95,0.157l0.693,0.134l0.658,0.218l0.563,0.278
		l0.565,0.381l0.211,0.21l0.19,0.229l0.308,0.529l0.117,0.121l0.117,0.064l0.244,0.076l0.405,0.037l0.247-0.045l0.138,4.661
		l-0.073,0.593l-0.332,0.588l-0.71,0.823l-1.09,0.778l-0.698,0.351l-2.632,0.626l-0.43-0.01l-0.43-0.065l-0.321,0.029l-0.717,0.243
		l-0.421,0.044l-0.698-0.122l-0.405,0.067l-2.243,0.788l-1.191,0.188l-1.071,0.332l-0.409,0.074l-0.338-0.006l-0.77-0.136
		l-0.47-0.025l-0.471,0.081l-0.539,0.329l-0.508,0.5l-0.774,1.209l-0.47,0.602l-1.927,2.027l-0.385,0.624l-0.308,0.817l-0.357,0.561
		l-0.978,1.176l-0.296,0.556l0.057,0.488l0.166,0.274l0.211,0.244l0.158,0.349l0.609,2.813l0.308,0.585l0.644,0.933l0.401,0.979
		l0.297,0.378l0.361,0.371l0.194,0.35l0.045,0.357l-0.097,0.242l-0.393,0.163l-0.316,0.08l-1.879,0.135l-0.421-0.284l-0.735-1.035
		l-0.397-0.415l-2.242-1.503l-0.544-0.618l-0.235-0.556l-0.016-0.49l0.04-0.584l-0.004-0.571l-0.081-0.581l-0.17-0.508l-1.314-1.868
		l-0.393-0.893l-1.136-4.053l-0.069-0.619l-0.004-0.552l0.057-0.982l-0.024-0.367l-0.134-0.213l-0.219-0.128l-3.942-0.896
		l-0.425,0.015l-0.86,0.229l-0.466-0.011l-0.446-0.215l-0.322-0.487l-0.121-0.476l-0.045-0.405l-0.069-0.312l-0.17-0.234
		l-0.494-0.351l-0.386-0.345l-0.271-0.449l-0.146-0.411l-0.081-0.393l0.008-0.338l0.13-0.639l-0.02-0.347l-0.109-0.344l-0.353-0.791
		l-0.121-0.356l-0.016-0.059l-0.008-0.113l0.024-0.169l0.126-0.269l0.227-0.315l0.263-0.302l0.223-0.307l0.122-0.291l-0.036-0.286
		l-0.186-0.104l-0.336,0.048l-0.962,0.446l-0.284,0.05l-0.198-0.073l-0.263-0.447l-1.205-2.539l-0.081-0.32l4.749-4.063l0.251-0.318
		l0.119-0.37v-0.456l-0.253-1.22l-0.057-0.493l0.061-0.322l0.162-0.281l0.236-0.251l0.207-0.265l0.19-0.388l0.121-0.508l0.057-0.526
		l-0.032-0.599l-0.154-0.418l-0.251-0.337l-3.139-2.948l-0.625-0.484l-0.393-0.232l-1.873-1.592l-0.334-0.414l-1.5-2.55l0.386-1.373
		l0.174-0.205l0.263-0.185l0.251,0.078l0.304,0.222l1.253,1.684l0.328,0.587l0.257,0.559l0.247,0.411l0.304,0.347l0.51,0.411
		l0.215-0.203l0.119-0.42l0.036-1.057l-0.049-0.496l-0.119-0.445l-0.304-0.777l-0.069-0.46l0.109-0.377l0.593-0.5l1.021-0.407
		l0.158-0.17l0.082-0.582l0.357-0.506l0.142-0.299l0.077-0.295l-0.126-0.682l-0.016-0.391l2.039-0.306l0.759,0.074l0.113,0.069
		l0.523,0.502l0.304,0.158l0.329,0.131l0.3,0.177l0.255,0.247l0.292,0.343l0.385,0.342l0.512,0.226l0.571,0.027l0.508-0.167
		l0.871-0.573l0.405-0.183l0.346-0.032l0.356,0.098l0.986,0.533l0.438,0.14l0.336-0.128l0.174-0.287l0.008-0.501l-0.057-0.451
		l0.154-1.221l1.006-2.65l0.409,0.215l0.117,0.178l0.089,0.212l0.069,0.293l0.036,0.04l0.638,0.144l0.348,0.02l0.195-0.035
		l0.097-0.085l0.028-0.04l0.04-0.13l0.182-0.169l0.358-0.155l0.802-0.083l3.415,0.743l0.879,0.082l0.658-0.037l0.539-0.264
		l0.978-0.686l0.527-0.214l0.304-0.007l0.17,0.089l0.122,0.209l0.082,0.224l0.142,0.556l0.198,0.594l0.126,0.282l0.255,0.415
		l0.105,0.221l0.02,0.199l-0.101,0.137l-0.279,0.085l-0.528,0.068l-0.134,0.07l-0.081,0.11l-0.126,0.501l-0.138,0.326l-0.223,0.307
		l-0.783,0.733l-0.105,0.325l0.077,0.346L473.788,71l0.393,0.201l0.43,0.075l2.656,0.062l0.548-0.058l0.555-0.152l2.011-0.926
		l0.771-0.209l0.373-0.054l1.038-0.035l0.936-0.208l0.435-0.013l0.28,0.117l0.247,0.229l0.587,0.842l0.216,0.223l0.324,0.197
		l0.304,0.088l0.401,0.069l0.251,0.149l0.564,0.566l0.608,0.386l0.443,0.175L489.149,72.771z"/>
                     <path :style="city_ids.includes(5) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(5)"
                           @mousemove="onMove($event)" class="st0" d="M497.649,77.419l0.779,0.017l0.365-0.105l0.385-0.199l0.401-0.472l0.204-0.43l0.134-0.522l0.089-0.946
		l0.061-0.363l0.121-0.299l0.207-0.25l1.326-0.963l0.47-0.247l0.199-0.401l0.158-0.45l0.208-0.336l0.361-0.155l0.47,0.135
		l0.438,0.247l0.686,0.257l0.271,0.189l0.271,0.251l0.316,0.226l1.047,0.45l0.357-0.078l0.273-0.382l0.393-0.903l-0.17-0.361
		l-0.344-0.252l-0.419-0.145l-0.259-0.194l-0.122-0.297l0.057-0.347l0.19-0.362l0.271-0.307l0.37-0.292l0.855-0.544l0.568-0.206
		l0.596-0.041l0.868,0.266l0.616,0.403l1.095,0.896l0.486,0.132l0.251-0.107l0.004-0.403l-0.105-0.272l-0.012-0.276l0.036-0.129
		l0.779-0.543l1.033,0.327l0.301,0.152l1.053,0.263l2.122-0.245l3.301,1.107l0.486-0.015l0.577-0.083l0.373-0.462l0.235-0.108
		l0.304,0.067l0.719,0.679l0.348,0.219l0.417,0.108l1.322,0.138l0.219,0.092l0.265,0.193l0.101,0.373l0.004,0.331l-0.053,0.4
		l0.024,0.392l0.186,0.403l0.389,0.08l0.486-0.1l1.578-0.619l0.581-0.076l0.583,0.045l0.629,0.179l1.269,1.005l1.857,2.626
		l0.134,1.57l0.34,1.418l-0.036,0.495l-0.109,0.419l-0.389,0.832l-0.267,0.371l-0.374,0.334l-0.551,0.304l-1.399,0.457l-0.51,0.261
		l-0.512,0.367l-0.458,0.429l-0.478,0.676l-0.138,0.473l0.061,0.974l-0.13,1.041l-0.02,0.514l0.109,0.313l0.3,0.184l0.349-0.118
		l0.255-0.005l0.146,0.122v0.353l-0.227,0.445l-0.978,1.046l-0.328,0.539l-0.324,2.141l-0.986,1.733l-0.122,0.403l-0.049,0.382
		l-0.057,1.232l0.033,0.536l0.182,0.465l0.735,1.244l0.982,2.127l0.142,0.489l0.219,0.524l-0.073,0.262l-0.54,1.139l-0.122,0.553
		l-0.004,0.433l0.089,0.424l0.223,0.734l0.147,0.334l-0.18,0.283l-0.174,0.065l-0.413,0.099l-0.474-0.038l-0.443-0.112l-0.385-0.201
		l-0.259-0.215l-0.779-0.951l-0.425-0.406l-0.336-0.427l-0.203-0.546l-0.02-0.519l0.32-1.702l0.012-1.326l0.044-0.485l0.081-0.334
		l0.223-0.542l0.057-0.246l-0.044-0.488l-0.263-0.433l-0.482-0.605l-0.435-0.127l-0.494,0.082l-0.588,0.22l-1.326,0.252
		l-0.654,0.001l-0.43,0.068l-0.397,0.141l-0.783,0.459l-0.231,0.293l-0.207,0.577l-0.061,0.095l-0.077,0.035l-0.486-0.068
		l-0.094,0.019l-0.604,0.275l-0.502-0.005l-0.386-0.151l-0.182-0.126l-0.097-0.182l-0.15-0.378l-0.105-0.404l-0.15-0.355
		l-0.239-0.303l-0.328-0.152l-0.325-0.066l-0.28,0.063l-0.223,0.24l-0.203,0.422l-0.385,1.158l-0.162,0.138l-0.333,0.214
		l-0.482-0.025l-0.482-0.422l-0.285-0.317l-0.624-0.543l-0.332-0.501l-0.004-0.525l0.434-0.951l0.032-0.52l-0.328-1.145l0.118-0.282
		l0.32-0.369l1.047-0.918l0.279-0.352l0.17-0.357L514,90.461l-0.19-0.109l-0.377-0.055l-0.548,0.003l-2.23-0.873l-0.917-0.84
		l-0.304-0.116l-0.255,0.017l-1.618,0.591l-1.205,0.582l-2.255,1.442l-1.873,0.474l-0.109,0.203l0.199,0.356l1.059,1l0.15,0.194
		l0.162,0.325l0.061,0.229l0.012,0.408l-0.126,0.257l-0.316,0.212l-0.531,0.211l-0.257,0.14l-0.397,0.281l-0.381,0.178l-0.577,0.095
		l-3.041,0.159l-0.535,0.163l-0.555,0.285l-0.658,0.441l-0.664,0.292l-0.876,0.096l-0.982-0.094l-1.309-0.308l-1.095-0.705
		l-1.796-0.892l-0.929-0.072l-0.247,0.045l-0.405-0.037l-0.244-0.076l-0.117-0.064l-0.117-0.121l-0.308-0.529l-0.19-0.229
		l-0.211-0.21l-0.565-0.381l-0.563-0.278l-0.658-0.218l-0.693-0.134l-1.95-0.157l-0.255-0.116l-0.172-0.164l-0.089-0.191
		l-0.053-0.249l0.15-0.354l0.403-0.491l0.993-0.86l0.767-0.917l0.425-0.667l0.186-0.526l0.305-1.72l0.073-0.84l-0.049-1.147
		l-0.139-1.154l-0.506-1.906l-0.008-0.264l0.097-0.201l0.178-0.089l0.251,0.051l0.224,0.128l0.219,0.188l0.482,0.576l0.113,0.098
		l0.316,0.159l0.386,0.065l0.608-0.305l0.097-0.1l0.219-0.291l0.215-0.137l0.423-0.191l0.604-0.073l1.561,0.023l0.466,0.123
		l0.411,0.204l0.434,0.151l0.466-0.096l0.957-0.591l0.648-0.212l0.763-0.471L497.649,77.419z"/>
                     <path :style="city_ids.includes(40) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(40)"
                           @mousemove="onMove($event)" class="st0" d="M529.225,104.745l-0.147-0.334l-0.223-0.734l-0.089-0.424l0.004-0.433l0.122-0.553l0.54-1.139l0.073-0.262
		l-0.219-0.524l-0.142-0.489l-0.982-2.127l-0.735-1.244l-0.182-0.465l-0.033-0.536l0.057-1.232l0.049-0.382l0.122-0.403l0.986-1.733
		l0.324-2.141l0.328-0.539l0.978-1.046l0.227-0.445v-0.353l-0.146-0.122l-0.255,0.005l-0.349,0.118l-0.3-0.184l-0.109-0.313
		l0.02-0.514l0.13-1.041l-0.061-0.974l0.138-0.473l0.478-0.676l0.458-0.429l0.512-0.367l0.51-0.261l1.399-0.457l0.551-0.304
		l0.374-0.334l0.267-0.371l0.389-0.832l0.109-0.419l0.036-0.495l-0.34-1.418l-0.134-1.57l0.482,0.746l0.336,0.657l0.269,0.297
		l0.373,0.241l0.677,0.138l0.532,0.037l3.171-0.446l0.279,0.07l0.32,0.214l0.528,1.097l0.405,0.38l0.831,0.01l0.5-0.273l0.417-0.412
		l0.308-0.404l0.296-0.216l0.249-0.056l1.464,0.212l0.186-0.035l0.085-0.097l0.044-0.091l0.024-0.085l0.085-0.118l0.109-0.018
		l0.571,0.04l0.484,0.126l0.543,0.277l0.393,0.383l0.174,0.634l-0.012,1.141l0.143,0.327l0.446-0.055l0.275-0.279l0.251-0.481
		l0.219-0.312l0.293-0.16l0.349,0.115l0.377,0.276l0.666,0.954l0.304,0.106l0.207-0.113l0.247-0.196l0.215,0.022l0.219,0.242
		l0.249,0.901l0.117,0.236l0.219,0.28l0.77,0.527l0.585,0.744L553.6,83.99l-2.473,0.992l-0.338,0.344l0.008,0.355l0.142,0.359
		l0.004,0.474l-0.166,1.084l0.032,0.473l0.085,0.488l-0.036,0.544l-0.279,0.614l-0.057,0.509l0.109,0.497l0.166,0.543l0.069,0.588
		l-0.117,0.851l-0.255,0.638l-0.365,0.619l-1.557,1.721l-0.937,1.264l-0.794,0.79l-0.86,1.286l-0.178,0.379l-0.065,0.269
		l0.028,0.163l0.053,0.148l0.032,0.165l0.012,0.175l-0.004,0.366l0.02,0.171l0.049,0.151l0.158,0.264l0.122,0.29l0.049,0.152
		l0.061,0.326l0.101,0.299l0.257,0.569l0.109,0.097l0.15,0.026l0.203-0.122l0.077-0.148l-0.004-0.156l-0.113-0.288l-0.045-0.156
		l-0.036-0.521l-0.024-0.165l-0.036-0.16l-0.065-0.142l-0.081-0.127l-0.097-0.116l-0.07-0.124l-0.004-0.206l0.099-0.602l0.077-0.23
		l0.093-0.187l0.105-0.118l0.138-0.069l0.154-0.043l0.17-0.093l0.15-0.172l0.174-0.366l0.117-0.199l0.138-0.106l0.123-0.016
		l0.413,0.198l0.17,0.03l0.352,0.016l0.178,0.048l0.15,0.084l0.117,0.11l0.091,0.125l0.053,0.149l0.02,0.171l-0.02,0.738
		l0.008,0.178l0.02,0.17l0.036,0.158l0.053,0.15l0.138,0.276l0.081,0.131l0.057,0.199l0.036,0.249l-0.109,1.716l0.016,0.174
		l0.033,0.16l0.393,1.207l0.036,0.212l0.024,0.274v0.458l-0.065,0.367l-0.069,0.259l-0.587,1.423l-0.504,0.934l-0.96,1.374
		l-0.5,1.202l-0.097,0.158l-0.332,0.348l-0.101,0.146l-0.15,0.312l-0.508,1.482l-0.255,1.004l-0.454,2.962l-0.596,2.286l-1.802,4.3
		l-3.094-0.566l-1.484-0.487l-5.637-3.313l-0.782-0.882l-0.498-1.566l0.251-1.136l-0.235-1.66v-0.365l0.093-0.418l0.134-0.249
		l0.381-1.328l0.397-2.009l0.126-1.132l0.036-1.057l0.219-1.312l-0.028-0.441l-0.15-0.541l-0.385-1.026l-0.207-0.293l-0.15-0.126
		l-0.203-0.079l-0.231-0.038l-0.342-0.007l-0.429,0.056l-1.306,0.38l-0.32,0.039L529.225,104.745z"/>
                     <path :style="city_ids.includes(24) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(24)"
                           @mousemove="onMove($event)" class="st0" d="M540.271,60.571l0.747-1.297l0.154-0.582l0.085-0.818l-0.069-1.211l-0.13-0.317l-0.227-0.218l-0.277-0.112
		l-0.288-0.151l-0.178-0.389l-0.049-0.341l0.036-0.559l-0.069-0.319l-0.093-0.163l-0.494-0.298l-0.212-0.322l-0.061-0.405
		l-0.142-0.494l-0.369-0.753l-0.049-0.071l-0.215-0.186l0.616-1.088l0.629-0.346l2.007-0.609l0.239-0.115l0.109-0.107l0.036-0.169
		l-0.061-0.251l-0.097-0.221l-0.004-0.334l0.162-0.414l0.662-0.843l0.563-0.477l2.94-1.568l0.727-0.287l0.604-0.141l0.446-0.008
		l0.269-0.138l0.146-0.23l0.109-0.764l0.101-0.35l0.296-0.442l0.207-0.203l0.515-0.285l0.443-0.155l1.898,1.078l0.207,0.305
		l0.211,0.442l-0.008,0.701l0.081,0.318l0.143,0.155l0.405,0.108l0.142,0.067l0.45,0.278l0.381,0.157l0.374,0.074l0.389,0.022
		l0.763-0.101l0.276-0.091l0.251-0.14l0.243-0.225l0.215-0.264l0.52-0.838l1.236,0.184l0.285,0.183l0.871,0.758l0.28,0.363
		l0.178,0.342l0.147,0.502l0.186,0.329l0.348,0.254l4.724,2.005l0.235,0.16l0.101,0.134l0.036,0.075l0.073,0.244l0.135,0.291
		l-3.043,1.683l-0.742,0.058l-1.063-0.341l-0.186,0.01l-0.041,0.192l0.365,1.01l0.049,0.269l0.02,0.34l0.049,0.329l0.172,0.337
		l0.288,0.473l0.138,0.495l-0.146,0.643l-0.406,0.328l-0.486,0.228l-0.45,0.109l-0.905,0.069l-2.604,1.315l-2.615,0.91l-0.244,0.183
		l-0.385,0.538l-0.304,0.176l-0.344,0.078l-0.512,0.035l-1.695,0.366l-0.349,0.239l-0.251,0.323l-0.316,0.674l-0.194,0.266
		l-0.273,0.165l-0.3,0.051l-0.527,0.025l-0.917-0.515l-1.322-0.12l-0.429,0.095l-0.267,0.167l-0.081,0.336l-0.089,0.968
		l-0.317,0.578l-0.547,0.396l-1.055,0.496l-0.713,0.23l-0.714,0.114l-0.328-0.155l-0.134-0.262l-0.053-0.276l-0.13-0.265
		l-0.271-0.294l-0.308-0.259l-0.22-0.304l-0.134-0.434l-0.069-0.456l-0.053-0.679L540.271,60.571z"/>
                     <path :style="city_ids.includes(25) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(25)"
                           @mousemove="onMove($event)" class="st0" d="M538.373,51.566l0.215,0.186l0.049,0.071l0.369,0.753l0.142,0.494l0.061,0.405l0.212,0.322l0.494,0.298
		l0.093,0.163l0.069,0.319l-0.036,0.559l0.049,0.341l0.178,0.389l0.288,0.151l0.277,0.112l0.227,0.218l0.13,0.317l0.069,1.211
		l-0.085,0.818l-0.154,0.582l-0.747,1.297l-0.693,0.201l-0.524,0.018l-0.385,0.189l-0.782,0.759l-0.544,0.395l-0.267-0.054
		l-0.073-0.289l0.008-0.47l-0.154-0.467l-2.434-1.457l-0.369-0.033l-0.308,0.145l-0.223,0.207l-0.309,0.202l-0.308,0.12
		l-1.294,0.345l-0.434,0.211l-0.474,0.312l-1.229,0.581l-0.049,0.077l-0.045,0.143l-0.089,0.205l-0.15,0.164l-0.2-0.016
		l-0.118-0.077l-0.636-0.871l-0.836-0.879l0.227-1.263l-0.138-0.331l-1.342-1.909l-2.351-2.445l-0.417-0.189l-0.747,0.06
		l-0.34-0.173l-0.081-0.303l0.101-0.355l0.243-0.316l0.247-0.214l0.219-0.158l0.516-0.252l0.267-0.06l0.588-0.226l1.687-0.214
		l0.334-0.191l0.13-0.4l-0.121-0.489l-0.322-0.841l-0.024-0.275l0.045-0.197l0.086-0.09l0.385-0.231l0.097-0.109l0.097-0.193
		l0.089-0.274l0.065-0.284l0.142-1.454l0.941,0.024l1.041,0.225l0.508-0.128l1.387-1.103l0.3,0.024l0.15,0.152l0.085,0.271
		l0.17,0.272l1.395,0.665l1.16,0.296l3.041-0.17l0.458,0.035l0.236,0.106v0.217l-0.082,0.298l-0.138,0.261l-0.198,0.139
		l-0.239-0.061l-0.32-0.143l-0.316-0.056l-0.289,0.01l-0.211,0.071l-0.158,0.1l-0.105,0.149l-0.057,0.206l0.016,0.26l0.057,0.375
		v0.105l-0.02,0.177l-0.069,0.143l-0.13,0.139l-0.178,0.139l-0.162,0.19l-0.069,0.217l0.093,0.235l0.284,0.125l0.405,0.049
		l0.415,0.2L538.373,51.566z"/>
                     <path :style="city_ids.includes(43) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(43)"
                           @mousemove="onMove($event)" class="st0" d="M513.251,68.307l0.049-0.987l-0.154-0.502l0.28-3.119l-0.17-0.65l-0.362-0.457l-0.417-0.216l-0.304-0.353
		l-0.101-0.231l-0.024-0.402l-0.057-0.197l-0.17-0.288l-0.316-0.328l-0.273-0.475l0.012-0.351l0.219-0.27l0.293-0.253l0.251-0.338
		l0.117-0.579l-0.211-0.316l-0.361-0.074l-0.475,0.025l-0.393-0.122l-0.324-0.499l0.028-0.641l0.174-0.777l0.044-2.079l-0.081-1.254
		l-0.251-0.577l-0.394-0.337l-0.478-0.044l-0.502,0.008l-0.508-0.07l-0.381-0.218l-0.259-0.41l-0.045-0.516l0.142-0.956l-0.02-0.543
		l-0.105-0.358l-0.19-0.35l-0.146-0.198l-0.394-0.414l1.006-0.25l0.316,0.046l0.496,0.135l0.401,0.214l0.6,0.23l0.633,0.017
		l0.916-0.079l0.512-0.18l0.304-0.287l0.138-0.335l0.271-0.382l0.394-0.281l0.964-0.415l0.524-0.086l0.413,0.035l0.904,0.28
		l1.379,0.139l1.031,0.242l0.828,0.086l0.466-0.023l0.3-0.098l0.158-0.182l0.15-0.219l0.178-0.186l0.585-0.245l0.701-0.42
		l0.22-0.006l0.158,0.088l0.146,0.402l0.113,0.157l0.231,0.124l3.386,0.673l1.221,0.071l-0.142,1.454l-0.065,0.284l-0.089,0.274
		l-0.097,0.193l-0.097,0.109l-0.385,0.231l-0.086,0.09l-0.045,0.197l0.024,0.275l0.322,0.841l0.121,0.489l-0.13,0.4l-0.334,0.191
		l-1.687,0.214l-0.588,0.226l-0.267,0.06l-0.516,0.252l-0.219,0.158l-0.247,0.214l-0.243,0.316l-0.101,0.355l0.081,0.303l0.34,0.173
		l0.747-0.06l0.417,0.189l2.351,2.445l1.342,1.909l0.138,0.331l-0.227,1.263l-0.223-0.01l-0.409,0.09l-0.393,0.148l-0.787,0.452
		l-0.693,0.624l-0.374,0.488l-0.174,0.644l0.065,0.992l-0.012,0.499l-0.15,0.501l-0.45,0.501l-0.739,0.511l-1.35,0.599l-1.667,0.511
		l-1.176,0.224l-0.51,0.159l-0.308,0.2l-0.186,0.315l-0.077,0.252l0.02,0.874l-2.122,0.245l-1.053-0.263l-0.301-0.152
		L513.251,68.307z"/>
                     <path :style="city_ids.includes(4) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(4)"
                           @mousemove="onMove($event)" class="st0" d="M533.961,75.778l-1.857-2.626l-1.269-1.005l-0.629-0.179l-0.583-0.045l-0.581,0.076l-1.578,0.619l-0.486,0.1
		l-0.389-0.08l-0.186-0.403l-0.024-0.392l0.053-0.4l-0.004-0.331l-0.101-0.373l-0.265-0.193l-0.219-0.092l-1.322-0.138l-0.417-0.108
		l-0.348-0.219l-0.719-0.679l-0.304-0.067l-0.235,0.108l-0.373,0.462l-0.577,0.083l-0.486,0.015l-3.301-1.107l-0.02-0.874
		l0.077-0.252l0.186-0.315l0.308-0.2l0.51-0.159l1.176-0.224l1.667-0.511l1.35-0.599l0.739-0.511l0.45-0.501l0.15-0.501l0.012-0.499
		l-0.065-0.992l0.174-0.644l0.374-0.488l0.693-0.624l0.787-0.452l0.393-0.148l0.409-0.09l0.223,0.01l0.836,0.879l0.636,0.871
		l0.118,0.077l0.2,0.016l0.15-0.164l0.089-0.205l0.045-0.143l0.049-0.077l1.229-0.581l0.474-0.312l0.434-0.211l1.294-0.345
		l0.308-0.12l0.309-0.202l0.223-0.207l0.308-0.145l0.369,0.033l2.434,1.457l0.154,0.467l-0.008,0.47l0.073,0.289l0.267,0.054
		l0.544-0.395l0.782-0.759l0.385-0.189l0.524-0.018l0.693-0.201l0.089,0.261l0.053,0.679l0.069,0.456l0.134,0.434l0.22,0.304
		l0.308,0.259l0.271,0.294l0.13,0.265l0.053,0.276l0.134,0.262l0.328,0.155l0.714-0.114l0.713-0.23l1.055-0.496l0.547-0.396
		l0.317-0.578l0.089-0.968l0.081-0.336l0.267-0.167l0.429-0.095l1.322,0.12l0.917,0.515l-0.024,1.533l1.132,2.496l0.786,1.156
		l0.498,0.475l0.905,1.071l0.397,0.319l0.398,0.212l1.061,0.302l0.431,0.243l0.227,0.426l0.409,1.054l0.348,0.206l1.683,0.081
		l0.483-0.103l0.207-0.265l0.077-0.405l0.024-0.382l0.113-0.285l0.474-0.433l0.219-0.367l0.163-0.451l0.267-0.39l0.401-0.258
		l0.66,0.035l0.512,0.252l0.438,0.301l1.82,1.857l-0.113,0.522l-0.008,0.112l0.008,0.191l0.081,0.384l-0.065,0.267l-1.013,2.381
		l-0.057,0.857l0.085,0.714l0.409,1.359l0.097,0.594l-0.081,0.46l-0.45,0.292l-0.419,0.143l-0.43,0.091l-0.409,0.166l-6.026,4.683
		l-0.585-0.744l-0.77-0.527l-0.219-0.28l-0.117-0.236l-0.249-0.901l-0.219-0.242l-0.215-0.022l-0.247,0.196l-0.207,0.113
		l-0.304-0.106l-0.666-0.954l-0.377-0.276l-0.349-0.115l-0.293,0.16l-0.219,0.312l-0.251,0.481l-0.275,0.279l-0.446,0.055
		l-0.143-0.327l0.012-1.141l-0.174-0.634l-0.393-0.383l-0.543-0.277l-0.484-0.126l-0.571-0.04l-0.109,0.018l-0.085,0.118
		l-0.024,0.085l-0.044,0.091l-0.085,0.097l-0.186,0.035l-1.464-0.212l-0.249,0.056l-0.296,0.216l-0.308,0.404l-0.417,0.412
		l-0.5,0.273l-0.831-0.01l-0.405-0.38l-0.528-1.097l-0.32-0.214l-0.279-0.07l-3.171,0.446l-0.532-0.037l-0.677-0.138l-0.373-0.241
		l-0.269-0.297l-0.336-0.657L533.961,75.778z"/>
                     <path :style="city_ids.includes(7) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(7)"
                           @mousemove="onMove($event)" class="st0" d="M467.178,122.072l1.879-0.135l0.316-0.08l0.393-0.163l0.097-0.242l-0.045-0.357l-0.194-0.35l-0.361-0.371
		l-0.297-0.378l-0.401-0.979l-0.644-0.933l-0.308-0.585l-0.609-2.813l-0.158-0.349l-0.211-0.244l-0.166-0.274l-0.057-0.488
		l0.296-0.556l0.978-1.176l0.357-0.561l0.308-0.817l0.385-0.624l1.927-2.027l0.47-0.602l0.774-1.209l0.508-0.5l0.539-0.329
		l0.471-0.081l0.47,0.025l0.77,0.136l0.338,0.006l0.409-0.074l1.071-0.332l1.191-0.188l2.243-0.788l0.405-0.067l0.698,0.122
		l0.421-0.044l0.717-0.243l0.321-0.029l0.43,0.065l0.43,0.01l2.632-0.626l0.698-0.351l1.09-0.778l0.71-0.823l0.332-0.588
		l0.073-0.593l-0.138-4.661l0.929,0.072l1.796,0.892l1.095,0.705l1.309,0.308l0.982,0.094l0.876-0.096l0.664-0.292l0.658-0.441
		l0.555-0.285l0.535-0.163l3.041-0.159l0.577-0.095l0.381-0.178l0.397-0.281l0.257-0.14l0.531-0.211l0.316-0.212l0.126-0.257
		l-0.012-0.408l-0.061-0.229l-0.162-0.325l-0.15-0.194l-1.059-1l-0.199-0.356l0.109-0.203l1.873-0.474l2.255-1.442l1.205-0.582
		l1.618-0.591l0.255-0.017l0.304,0.116l0.917,0.84l2.23,0.873l0.548-0.003l0.377,0.055l0.19,0.109l0.024,0.367l-0.17,0.357
		l-0.279,0.352l-1.047,0.918l-0.32,0.369l-0.118,0.282l0.328,1.145l-0.032,0.52l-0.434,0.951l0.004,0.525l0.332,0.501l0.624,0.543
		l0.285,0.317l0.482,0.422l0.482,0.025l0.333-0.214l0.162-0.138l0.385-1.158l0.203-0.422l0.223-0.24l0.28-0.063l0.325,0.066
		l0.328,0.152l0.239,0.303l0.15,0.355l0.105,0.404l0.15,0.378l0.097,0.182l0.182,0.126l0.386,0.151l0.502,0.005l0.604-0.275
		l0.094-0.019l0.486,0.068l0.077-0.035l0.061-0.095l0.207-0.577l0.231-0.293l0.783-0.459l0.397-0.141l0.43-0.068l0.654-0.001
		l1.326-0.252l0.588-0.22l0.494-0.082l0.435,0.127l0.482,0.605l0.263,0.433l0.044,0.488l-0.057,0.246l-0.223,0.542l-0.081,0.334
		l-0.044,0.485l-0.012,1.326l-0.32,1.702l0.02,0.519l0.203,0.546l0.336,0.427l0.425,0.406l0.779,0.951l0.259,0.215l0.385,0.201
		l0.443,0.112l0.474,0.038l0.413-0.099l0.174-0.065l0.18-0.283l0.474,0.035l0.32-0.039l1.306-0.38l0.429-0.056l0.342,0.007
		l0.231,0.038l0.203,0.079l0.15,0.126l0.207,0.293l0.385,1.026l0.15,0.541l0.028,0.441l-0.219,1.312l-0.036,1.057l-0.126,1.132
		l-0.397,2.009l-0.381,1.328l-0.134,0.249l-0.093,0.418v0.365l0.235,1.66l-0.251,1.136l-0.909,0.787l-0.913,0.573l-0.94,0.354
		l-0.828,0.156l-0.827,0.016l-0.56-0.101l-0.385-0.123l-0.494-0.235l-0.398-0.289l-0.279-0.31l-0.19-0.342l-0.263-0.293
		l-1.517-0.712l-4.157-1.138l-0.474-0.017l-5.09,1.077l-13.625-1.739l-2.498-0.796l-1.626-0.76l-0.093-0.028l-0.105,0.049
		l-0.142,0.173l-0.17,0.432l0.036,0.516l-0.057,0.439l-0.293,0.318l-0.47,0.252l-1.071,0.424l-0.304,0.182l-0.284,0.28l-0.186,0.299
		l-0.19,0.389l-0.109,0.396l-0.061,0.388l0.097,0.237l0.138,0.166l1.853,1.62l0.958,1.173l0.814,1.2l0.276,0.534l0.423,1.093
		l0.259,1.165l0.081,0.682l0.024,1.556l-1.018,6.691l-0.219,0.717l-0.328,0.62l-1.124,1.168l-1.379,1.796l-2.519,4.286
		l-18.69-11.157l-1.156-0.937l-0.551-0.59l-0.163-0.313l-0.199-0.84l-0.482-3.199l-0.097-0.363l-0.178-0.322l-0.296-0.188
		l-0.868-0.223l-0.531-0.215l-0.406-0.335l-0.195-0.264l-0.032-0.222l0.146-0.501l0.033-0.285L467.178,122.072z"/>
                     <path :style="city_ids.includes(17) ? 'fill:#3DB2FF' : ''"
                           @mouseleave="onLeave"
                           @mouseover="onHover(17)"
                           @mousemove="onMove($event)" class="st0" d="M487.159,165.701l-20.961-5.881l-0.277-1.03l-1.252-3.327l-0.868-1.708l-0.664-0.891l-0.803-0.871
		l-10.122-8.891l-10.685-12.042l-1.871-3.302l-0.296-0.713l-0.219-0.729l-0.336-2.33l-0.629-1.835l-0.271-0.325l-0.591-0.249
		l-1.266,0.017l-0.381-0.052l-0.255-0.101l-0.609-0.351l-0.3-0.078l-0.365,0.069l-0.423,0.291l-1.966,1.817l-0.34,0.396
		l-0.194,0.399l-0.247,0.775l-0.273,0.264l-0.373,0.217l-1.205,0.431l-2.363,0.4l-0.281,0.103l-0.154,0.14l-0.097,0.256l-0.04,0.375
		l0.101,3.399l-0.049,0.957l-0.109,0.759l-0.093,0.147l-0.182,0.038l-0.901,0.017l-2.092-0.247l-0.725-0.009l-0.394-0.2
		l-0.223-0.417l-0.207-1.741L421.3,129.3l0.142-0.767l0.543-1.679l0.057-0.504l-0.085-0.53l-0.316-0.758l-0.336-0.604l-0.401-0.549
		l-0.949-1.083l-0.34-0.536l-0.178-0.526l-0.471-2.747l-0.207-0.758l-0.259-0.512l-0.324-0.4l-0.561-0.544l-0.332-0.44l-0.227-0.837
		l-0.061-1.138l0.203-1.788l0.215-1.018l0.358-0.697l0.733-0.816l0.219-0.509l0.126-0.396l-0.174-1.693l0.32-2.653l1.806-2.056
		l0.555-0.932l1.221-3.238l0.429-0.872l0.077-0.044l1.841-0.515l0.394-0.204l0.219-0.259l-0.093-0.358l-0.328-0.403l-5.674-4.579
		l-0.203-0.215l-0.094-0.239l0.024-0.343l0.139-0.417l-0.069-0.458l-0.147-0.425l-0.357-0.773l-0.178-0.293l-0.182-0.127
		l-0.482-0.097l-0.236-0.079l-0.474-0.279l-0.203-0.192l-0.158-0.255l-0.028-3.52l5.016-1.731l1.29-0.262l0.061,0.027l1.988,1.556
		l0.377,0.197l0.446,0.123l0.442-0.017l0.451-0.134l0.421-0.229l0.389-0.37l0.313-0.412l0.442-0.797l0.126-0.194l0.296-0.362
		l0.466-0.47l3.091-2.45l0.276-0.312l0.134-0.294l0.065-0.379l0.004-0.445l-0.109-0.937l-0.008-0.275l0.04-0.642l-0.032-0.318
		l-0.243-0.895l-0.097-0.484v-0.469l0.17-0.36l0.227-0.123l0.336-0.071l0.462-0.039l0.791-0.188l0.551,0.123l0.342,0.311
		l0.138,0.346l0.19,0.762l0.142,0.362l0.194,0.289l0.502,0.361l0.154,0.19l0.109,0.306l0.188,0.868l0.243,0.234l0.425,0.125
		l2.515-0.452l0.478-0.274l0.279-0.271l0.227-0.456l0.374-1.707l0.284-0.523l0.369-0.357l0.271-0.062l0.22,0.133l0.336,0.695
		l0.211,0.332l0.292,0.28l0.413,0.162l0.39,0.065l1.118-0.118l1.5,2.55l0.334,0.414l1.873,1.592l0.393,0.232l0.625,0.484
		l3.139,2.948l0.251,0.337l0.154,0.418l0.032,0.599l-0.057,0.526l-0.121,0.508l-0.19,0.388l-0.207,0.265l-0.236,0.251l-0.162,0.281
		l-0.061,0.322l0.057,0.493l0.253,1.22v0.456l-0.119,0.37l-0.251,0.318l-4.749,4.063l0.081,0.32l1.205,2.539l0.263,0.447
		l0.198,0.073l0.284-0.05l0.962-0.446l0.336-0.048l0.186,0.104l0.036,0.286l-0.122,0.291l-0.223,0.307l-0.263,0.302l-0.227,0.315
		l-0.126,0.269l-0.024,0.169l0.008,0.113l0.016,0.059l0.121,0.356l0.353,0.791l0.109,0.344l0.02,0.347l-0.13,0.639l-0.008,0.338
		l0.081,0.393l0.146,0.411l0.271,0.449l0.386,0.345l0.494,0.351l0.17,0.234l0.069,0.312l0.045,0.405l0.121,0.476l0.322,0.487
		l0.446,0.215l0.466,0.011l0.86-0.229l0.425-0.015l3.942,0.896l0.219,0.128l0.134,0.213l0.024,0.367l-0.057,0.982l0.004,0.552
		l0.069,0.619l1.136,4.053l0.393,0.893l1.314,1.868l0.17,0.508l0.081,0.581l0.004,0.571l-0.04,0.584l0.016,0.49l0.235,0.556
		l0.544,0.618l2.242,1.503l0.397,0.415l0.735,1.035l0.421,0.284l0.065,0.812l-0.033,0.285l-0.146,0.501l0.032,0.222l0.195,0.264
		l0.406,0.335l0.531,0.215l0.868,0.223l0.296,0.188l0.178,0.322l0.097,0.363l0.482,3.199l0.199,0.84l0.163,0.313l0.551,0.59
		l1.156,0.937l18.69,11.157l-2.973,4.099l-0.591,3.262l-0.844,3.007L487.159,165.701z"/>
</g>
 </svg>
    <div>
      <div class="d-block">
        <v-icon small color="blue darken-4">mdi-checkbox-blank</v-icon>
        +200
      </div>
      <div class="d-block">
        <v-icon small color="blue darken-3">mdi-checkbox-blank</v-icon>
        150-200
      </div>
      <div class="d-block">
        <v-icon small color="blue darken-2">mdi-checkbox-blank</v-icon>
        100-150
      </div>
      <div class="d-block">
        <v-icon small color="blue darken-1">mdi-checkbox-blank</v-icon>
        50-100
      </div>
      <div class="d-block">
        <v-icon small color="blue lighten-1">mdi-checkbox-blank</v-icon>
        20-50
      </div>
      <div class="d-block">
        <v-icon small color="blue lighten-2">mdi-checkbox-blank</v-icon>
        0-20
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      city_ids: [16, 34, 22, 18, 1, 2]
    }
  },
  methods: {
    onLeave() {

    },
    onHover() {

    },
    onMove() {

    },
  },
}
</script>

<style scoped>
.st0 {
  fill: #e9ecef66;
  stroke: #000000;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  cursor: pointer;
}
</style>