<template>
  <div>
    <v-container fluid>
      <AnalyticCards/>
      <v-row>
        <v-col class="d-flex flex-column" cols="12" lg="5">
          <v-card class="rounded-lg shadow flex d-flex flex-column">
            <v-card-title>Liste des camapgnes</v-card-title>
            <v-card-text class="flex pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Campagne
                    </th>
                    <th class="text-left">
                      Total des tâches
                    </th>
                    <th class="text-left">
                      Progresse
                    </th>
                    <th class="text-left">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,i) in items" :key="i">
                    <td>{{ item.name }}</td>
                    <td>{{ item.task }}</td>
                    <td>
                      {{ item.progress }} %
                      <v-progress-linear
                          v-model="item.progress"
                          :color="$func.progressColor(item.progress)"
                          height="5"
                      ></v-progress-linear>
                    </td>
                    <td>
                      <SwitchStatus :url="item.name" :value="item.status" @refresh="setColor"/>
<!--                      <Delete :url="item.name" @refresh="setColor"/>-->
<!--                      <Link :url="item.name" icon="mdi-text-box-check-outline" color="blue" title="Test"/>-->
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="d-flex flex-column" cols="12" lg="7">
          <v-card class="rounded-lg shadow flex d-flex flex-column">
            <v-card-title>Analyse par wilaya</v-card-title>
            <v-card-text class="flex">
              <v-row>
                <v-col cols="6">
                  <Provinces/>
                </v-col>
                <v-col cols="6">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Wilaya
                        </th>
                        <th class="text-right">
                          Total des tâches
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,i) in cities" :key="i" class="hover-tr">
                        <td>{{ item.name }}</td>
                        <td class="text-right">
                          <v-chip small :color="setColor(item.task)" dark>{{ item.task }}</v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import Provinces from './components/Provinces'
import AnalyticCards from "@/views/dashboard/components/AnalyticCards";

export default {
  components: {
    AnalyticCards,
    Provinces
  },
  data() {
    return {
      items: [
        {
          name: 'Campagne Alger',
          task: '25/80',
          progress: 91,
          status: true,
        },
        {
          name: 'Campagne Annaba',
          task: '90/122',
          progress: 88,
          status: true,
        },
        {
          name: 'Campagne Oran',
          task: '12/22',
          progress: 15,
          status: false,
        },
        {
          name: 'Campagne Sétif',
          task: '54/80',
          progress: 72,
          status: true,
        },
        {
          name: 'Campagne Blida',
          task: '35/95',
          progress: 45,
          status: false,
        },
      ],
      cities: [
        {
          name: 'Sétif',
          task: '782',
        },
        {
          name: 'Annaba',
          task: '200',
        },
        {
          name: 'Oran',
          task: '120',
        },
        {
          name: 'Alger',
          task: '54',
        },
      ],
    }
  },
  methods: {
    setColor(el) {
      if (el <= 60) {
        return 'red'
      }
      if (el <= 200) {
        return 'primary'
      }
      if (el <= 800) {
        return 'success'
      }
    }
  },
}
</script>

<style scoped>
</style>