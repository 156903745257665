<template>
  <div>
    <v-row>
      <v-col cols="12" lg="3">
        <v-card class="rounded-lg shadow" color="primary" dark>
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="10">
                <h3 class="white--text my-3">{{ count.campaigns }}</h3>
                <p class="white--text text-uppercase">{{ $t('dashboard.key1') }}</p>
              </v-col>
              <v-col cols="2">
                <v-avatar color="white" size="45">
                  <v-icon dark size="25" color="primary">mdi-folder-multiple-outline</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="3">
        <v-card class="rounded-lg shadow">
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="10">
                <h3 class="text--primary my-3">{{ count.tasks }}</h3>
                <p class="text--secondary text-uppercase">{{ $t('dashboard.key2') }}</p>
              </v-col>
              <v-col cols="2">
                <v-avatar color="primary" size="45">
                  <v-icon dark size="25">mdi-text-box-check-outline</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="3">
        <v-card class="rounded-lg shadow">
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="10">
                <h3 class="text--primary my-3">{{ count.salepoints }}</h3>
                <p class="text--secondary text-uppercase">{{ $t('dashboard.key3') }}</p>
              </v-col>
              <v-col cols="2">
                <v-avatar color="primary" size="45">
                  <v-icon dark size="25">mdi-database-outline</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="3">
        <v-card class="rounded-lg shadow">
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="10">
                <h3 class="text--primary my-3">{{ count.accounts|toCurrency }} {{ $t('dashboard.key5') }}</h3>
                <p class="text--secondary text-uppercase">{{ $t('dashboard.key4') }}</p>
              </v-col>
              <v-col cols="2">
                <v-avatar color="blue" size="45">
                  <v-icon dark size="25">mdi-currency-usd</v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: {
        campaigns: 15,
        tasks: 233,
        salepoints: 8590,
        accounts: 36200,
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>